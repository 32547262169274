div#confirmation-codes {
  display: flex;
  justify-content: space-between;
  margin: 30px auto;
  max-width: 352px;
  >p{
    width: 8px;
  }
  >div{
    position: relative;
    width: 50px; 
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    height: 70px;
    overflow: hidden;
    >div{
      p{
        top: 5px;
        width: 50px; 
        height: 60px;
        line-height: 60px;
        position: absolute;
        background-color: white;
        font-size: 36px;
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        z-index: 100;
      }
    }
    >div.mask{
      p{
        left: 22px;
        width: 15px; 
      }
    }

    input {
      background: #fff;
      color: #2f374e;
  
      font-variant-numeric: tabular-nums;
      font-family: Manrope, sans-serif;
      font-size: 36px;
      font-weight: 500;
      height: 70px;
      letter-spacing: -0.02em;
      line-height: 62px;
      padding: 0 10px;
      text-align: center;
      border: none;
      width: 50px; 
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  &.error {
    input {
      border: 1px solid #FF6030; 
    } 
  } 
}

