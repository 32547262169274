.prekyu main#confirmation-code {
  > div {
    h2 {
      color: #f15a22;
    }
  }
}

main#confirmation-code {
  padding-top: 0;
  > div {
    background: #fff;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 10vh;
    max-width: 460px;
    padding-bottom: 30px;
    padding-top: 30px;
    width: 90vw;

    h2 {
      color: #00b9b2;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      margin: 0 auto 30px;
      text-align: center;
    }

    p {
      color: #2f374e;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
    }

    form {
      margin-top: 30px;

      div.buttons {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 60%;
      }

      div.submit-button {
        margin-bottom: 30px;
        margin-top: 30px;
        text-align: center;
      }
      div.mini-submit-button {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}
