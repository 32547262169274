
.seven-detail-wrapper{
  display: flex;
  .vertical-spacer{
    width: 31px;
  }
}

.prekyu .seven-detail{
  a{
    background: #FF9600;
  }
}

.seven-detail{
  padding: 0;
  width: calc(100% - 62px);

  .spacer{
    height: 16px;
    border: none;
    margin: 0;
    padding: 0;
  }
  a{
    overflow: hidden;
    display: flex;
    background: #00B9B2;
    border-radius: 4px;
    position: relative;
    img.receive-method{
      display : block;
      margin: 6px 0 0 16px;
    }
    p{
      margin: 12px 0 0 16px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #FFFFFF;
    }
    img.right-arrow{
      position: absolute;
      top: 16px;
      right: 16px;
      display : block;
    }
  }
}
