main#bank-setting {
  padding: 0;
  margin: 0 auto;
  width: fit-content;

  >section {
    display: flex;
    >section:first-child {
      max-width: 620px;
      width: 70vw;
      @media screen and (max-width: 680px) {
        width: 90vw; }
      border-radius: 8px;
      background: #F5F5F5;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
      text-align : left;

      border-radius: 8px;
      background: #F5F5F5;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);

      section {
        padding: 30px;
        p.caution {
          font-size: 14px;
          line-height: 18px;
          text-align: left;
          color: #FF6030;
          margin: -10px 0 10px 0;
        }
 
        >h2 {
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
          text-align: left;
          color: #2F374E;
          margin-bottom: 20px;
        } 

        >p{
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          text-align: left;
          color: #2F374E;
          margin-bottom: 20px;
        }
        >ul{
          list-style: none;
          width: 100%;
          >li{
            width: 100%;
            margin-bottom : 20px;
            h3{
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              text-align: left;
              color: #2F374E;
            }
            >h3{
              margin-bottom: 12px;
            }

            div{
              display: flex;
              margin-bottom: 4px;
              p{
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                text-align: left;
                color: #707070;
              }
            }
            input[type="text"]{
              padding: 10px;
              width: 75%;
              height: 40px;
              border-radius: 6px;
              background: #fff;
              border: 1px solid #D3D3D3;
            }
          }
          div.modal{
            position: relative;
            width: 100%;
            height: 0;
            >ul{
              position: absolute;
              list-style: none;
              width: 292px;
              height: 300px;
              left: 5%;
              border-radius: 8px 8px 0px 0px;
              >li{
                padding: 14px 10px;
                height: 50px;
                background: #fff;
                box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.16);
                font-weight: bold;
                font-size: 14px;
                line-height: 22px;
                text-align: left;
                color: #2F374E;
              }
            }
          }
          div.scroll{
            >ul{
              overflow: scroll;
            }
          }
        }
        .buttons{
          @media screen and (min-width: 680px) {
            display: flex;
            justify-content: center;
            margin: 0 auto;
          }
          .submit-button{
            margin-top: 20px;
            text-align: center;
          }
          .cancel-button{
            @media screen and (min-width: 680px) {
              margin-left: 5%;
            }
            margin-top: 20px;
            text-align: center;
          }
        }
      }
    }
  }

  footer {
    margin-top : 50px;
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    color: #fff;

    background-color: unset;
    position: unset; } }

@media screen and (max-width: 680px) {
  main#settings {
    >section {
      >section:first-child {
        section {
          >div:first-child {
            h2 {} }
          >div:last-child {
            h2 {
              img {}
              p {} }
            dl {
              dt {}
              dd {
                ul {
                  li {
                    p {} } } } }
            .whiteout-button {
              position: relative;
              right: 0;
              bottom: 0;
              text-align: right;
              button {} } } }

        div.languages,div.terms,div.privacy-policy {
          h3 {
            p {}
            img {} }
          >p {}
          >img {} }
        div.logout {
          button {} } } }
    footer {} } }

