$standardWidth: 300px;
$standardInnerWidth: 220px;

main.reminder-sended {
  padding: 0px;

  .contents-inner {
    color: #fff;
    max-width: 620px;
    padding: 0;
    text-align: center;
    h2 {
      margin-bottom: 24px; 
    }

    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 24px;
      text-align: center; 
    }

    .submit{
      .error {
        color: #cc0000;
        font-size: 13px;
        text-align: center; 
        margin-top: 13px;
        margin-bottom: 8px;
      }
      div{
        margin-right:0;
        margin-left:0;
        margin-bottom:32px;
      }

      div:first-child{
        margin-top: 40px ; 
      }
    }

    .left-allow-link {
      margin-top: 24px; 
    } 
  } 
}

