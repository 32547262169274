.customer-number {
  input {
    -moz-appearance: textfield;
    &::placeholder {
      color: #dedede;
      font-variant-numeric: tabular-nums;
      font-family: Manrope, sans-serif;
      font-size: 18px;
      font-weight: 500;
      text-align: left; 
    } 
  }
}

