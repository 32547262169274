.inputs {
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: left; }

  input {
    background: #fff;
    border: none;
    border-radius: 6px;
    font-size: 20px;
    height: 50px;
    margin-top: 4px;
    padding: 0.5rem 1rem;
    position: relative;
    text-align: left;
    width: 100%; } }

