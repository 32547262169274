.settings-logout-confirm {
  max-width: 620px;
  width: 90vw;
  height: 300px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #707070;
  margin: 10vh auto;
  position: relative;
  >section {
    width: 55%;
    @media screen and (max-width: 540px) {
      width: 65%; }
    @media screen and (max-width: 480px) {
      width: 90%; }
    margin: 0 auto;
    padding-top: 100px;
    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      text-align: left;
      color: #2F374E; }

    div.whiteout-button {
      margin-top: 30px;
      text-align: center;
      button {
        width: 220px;
        height: 60px;
        border-radius: 30px;
        background: transparent;
        border: 2px solid #2F374E;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #2F374E; } }

    div.whiteout-button:last-child {
      text-align: center;
      top: calc(100% + 20px);
      position: absolute;
      left: 0;
      width: 100%;
      button {
        width: 160px;
        height: 40px;
        border-radius: 20px;
        background: transparent;
        border: 2px solid #fff;
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #fff; } } } }

