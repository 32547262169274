.home-result-seven {
  >section {
    hr {
      border: 1px solid #2F374E; }
    dl {
      display: flex;
      dt {
        width: 35%;
        margin-right: 6px;
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
        text-align: right;
        color: #2F374E;
        margin-top : 10px; }

      dd {
        // width: calc(60% - 6px);
        font-variant-numeric: tabular-nums;
        font-family: Manrope;
        font-weight: 500;
        font-size: 34px;
        letter-spacing: -0.02em;
        line-height: 46px;
        text-align: left;
        color: #2F374E; } }
    >a{
      display: block;
      >img {
        display: block;
        margin: 20px auto 0; 
      } 
    }
    >p.thanks{
      font-family: 'Noto Sans CJK JP';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #2F374E;
    }
    >p.confirmation{
      font-family: 'Noto Sans CJK JP';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      color: #2F374E;
    }
  }
}
