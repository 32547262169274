main#home {
  padding: 0;
  margin: 0 auto;
  width: fit-content;
  > section {
    display: flex;
    > section:first-child {
      max-width: 620px;
      width: 70vw;
      @media screen and (max-width: 680px) {
        width: 90vw;
      }
      border-radius: 8px;
      background: #f5f5f5;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
      text-align: center;

      section.forms {
        width: 70%;
        @media screen and (max-width: 680px) {
          width: 88%;
        }
        margin: 0 auto;

        div.form-number-and-submit {
          h2 {
            margin-top: 20px;
            margin-bottom: 20px;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            color: #2f374e;
          }

          > div {
            height: 98px;
            margin: 0 auto;
            border-radius: 10px;
            background: #fff;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
            position: relative;
            padding-right: calc(2% + 54px);
            // miniのボックスが出てるときは右の幅を小さくする
            @media screen and (max-width: 680px) {
              padding-right: calc(2% + 34px);
            }
            overflow: hidden;
            > div {
              display: flex;
              justify-content: center;
              div.input {
                position: relative;
                input:disabled {
                  background: none;
                }
                input {
                  text-align: right;
                  width: 75px;
                  height: 65px;
                  font-variant-numeric: tabular-nums;
                  font-family: Manrope;
                  font-weight: 500;
                  font-size: 54px;
                  letter-spacing: -0.02em;
                  line-height: 62px;
                  color: #2f374e;
                  border: none;
                  margin-top: 6px;
                  margin-left: 0;
                  padding: 0 0 0 12px;
                  text-align: right;
                }

                img.under-bar {
                  width: 60px;
                  position: absolute;
                  left: auto;
                  right: 2px;
                  bottom: 0;
                }
              }

              div.fee {
                width: 125px;
                height: 65px;
                font-variant-numeric: tabular-nums;
                font-family: Manrope;
                font-weight: 500;
                font-size: 54px;
                letter-spacing: -0.02em;
                line-height: 62px;
                text-align: left;
                color: #2f374e;
                border: none;
                margin-top: 14px;
              }
              @media screen and (max-width: 960px) {
                div.input {
                  img.under-bar {
                    width: 64px;
                    right: 0;
                  }
                }
              }
              @media screen and (max-width: 680px) {
                div.input {
                  input {
                    width: 75px;
                  }
                  img.under-bar {
                  }
                }
                div.fee {
                  width: 115px;
                }
              }

              div.yen {
                font-weight: bold;
                font-size: 20px;
                line-height: 46px;
                text-align: right;
                color: #2f374e;
                vertical-align: bottom;
                margin-top: 31px;
              }
            }

            div.change-amount {
              position: absolute;
              right: 2%;
              height: 100%;
              width: 54px;
              display: block;
              @media screen and (max-width: 680px) {
                display: none;
              }
              button {
                position: absolute;
                border: none;
                background: none;
                margin: 0;
                padding: 0;
                width: 54px;
                height: 36px;
                cursor: pointer;
                left: 0;
                img {
                  margin: 0;
                  padding: 0;
                }

                &.upper {
                  top: 6px;
                }
                &.lower {
                  top: auto;
                  bottom: 6px;
                }
              }
              &.mini {
                display: none;
                width: 34px;
                right: 5%;
                @media screen and (max-width: 680px) {
                  display: block;
                }
                button {
                  &.upper {
                    top: 10px;
                  }
                  &.lower {
                    top: auto;
                    bottom: 10px;
                  }
                }
              }
            }
          }
          > div.not-editable {
            padding-right: 0;
            > div {
              margin: 2% auto 0;
              img.under-bar {
                left: calc(75% - 20px - 125px - 5% + 56px + 16px);
                position: absolute;
                bottom: 15px;
              }
              div.input {
                input {
                  width: 40px;
                  padding: 0;
                }
              }
            }
          }
        }

        div.includes-fee {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-align: right;
          color: #2f374e;
          margin-top: 2px;
        }

        div.payment-methods {
          margin-top: 20px;
          position: relative;
          > h2 {
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            color: #2f374e;
          }

          > img.check {
            top: 30px;
            left: calc(50% - 20px);
            width: 40px;
            height: 40px;
            position: absolute;
            transition: 0.2s;
            z-index: 15;
            &.seven-selected {
              left: calc(100% - 20px);
            }
          }

          > div {
            height: 120px;
            margin: 20px auto 0;
            border-radius: 8px;
            background: #fff;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
            display: flex;
            position: relative;

            div.payment-method-card {
              width: 50%;
              height: 120px;
              text-align: center;
              z-index: 11;
              > img {
                width: 74.76px;
                height: 50px;
                margin-top: 20px;
              }
              z-index: 13;

              h3 {
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #2f374e;
                opacity: 0.7;
                z-index: 13;
                margin-top: 5px;
              }

              div.seven-atm {
                margin-top: 16px;
              }

              &-single {
                width: 100%;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
              }
              &-single.seven {
                display: flex;

                div.seven-atm-messages {
                  text-align: left;
                  margin-left: 8px;
                }
              }
            }

            div.payment-method-card-background-selected {
              position: absolute;
              height: 120px;
              width: 50%;
              background: #ffcf43;
              left: 0;
              transition: 0.2s;
              background: #ffcf43;
              border-radius: 8px 0 0 8px;
              box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
              z-index: 12;
              &.seven-selected {
                left: 50%;
                border-radius: 0 8px 8px 0;
              }
            }
            div.payment-method-card.left {
              border-radius: 8px 0 0 8px;
            }
            div.payment-method-card.right {
              border-radius: 0 8px 8px 0;
            }

            div.payment-method-card.selected {
              h3 {
                opacity: 1;
              }
            }

            div.payment-method-card.maintenance {
              background-color: #d3d3d3;
            }
          }
        }

        div.fee-and-amount {
          width: 192px;
          margin: 30px auto;
          div.fee {
            display: flex;
            justify-content: space-between;
            > div:nth-child(1) {
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              text-align: left;
              color: #2f374e;
            }

            > div:nth-child(2) {
              display: flex;
              justify-content: right;
              > div:nth-child(1) {
                font-variant-numeric: tabular-nums;
                font-family: Manrope;
                font-weight: 500;
                font-size: 16px;
                letter-spacing: -0.02em;
                line-height: 26px;
                text-align: center;
                color: #2f374e;
                margin-top: -2.5px;
              }

              > div:nth-child(2) {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #2f374e;
              }
            }
          }

          hr {
            margin: 0 auto;
            width: 191px;
            height: 0px;
            background: transparent;
            border: 1px solid #707070;
          }

          div.amount {
            display: flex;
            justify-content: space-between;

            > div:nth-child(1) {
              font-weight: bold;
              font-size: 16px;
              line-height: 22px;
              text-align: left;
              color: #2f374e;
              margin-top: 10px;
            }

            > div:nth-child(2) {
              display: flex;
              justify-content: right;
              div:nth-child(1) {
                width: 85px;
                height: 29px;
                font-variant-numeric: tabular-nums;
                font-family: Manrope;
                font-weight: 500;
                font-size: 26px;
                letter-spacing: -0.02em;
                line-height: 38px;
                text-align: right;
                color: #2f374e;
              }

              div:nth-child(2) {
                width: 16px;
                height: 24px;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                text-align: left;
                color: #2f374e;
                margin-top: 10px;
              }
            }
          }
        }

        button.submit {
          max-width: 80vw;
          width: 280px;
          height: 70px;
          margin: 20px auto 50px;
          border-radius: 35px;
          background: #ffcf43;
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.16);
          border: none;

          display: flex;
          justify-content: center;
          cursor: pointer;
          &-ng {
            cursor: not-allowed;
            background: #d3d3d3;
          }

          p {
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            text-align: left;
            color: #2f374e;
            margin-left: 15px;
            margin-top: 17px;
          }

          img {
            width: 40px;
            height: 25px;
            margin-top: 25px;
          }
        }
      }
    }
  }

  footer {
    margin-top: 50px;
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    color: #fff;

    background-color: unset;
    position: unset;
  }
}
