
.cria header#password-reminder{
  h1 img {
    width: 67px; 
  } 
}

.prekyu header#password-reminder{
  h1 img {
    width: 180px; 
  } 
}
header#password-reminder{
  padding-bottom: 30px;
  padding-top: 40px;

  @media screen and (max-width: 760px) {
    padding-bottom: 0;
    padding-top: 88px; 
  }

  h1 img {
    height: auto;
    margin: 0 auto;
  } 
}

