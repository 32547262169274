main#accounting-history {
  padding: 0;
  margin: 0 auto;
  width: fit-content;

  img.payment-method {
    display: block;
    width: 29.93px;
    height: 28px;
    cursor: pointer;
  }

  button.detail {
    width: 60px;
    height: 30px;
    border-radius: 15px;
    background: #e2e2e2;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #2f374e;
    box-shadow: 0;
    border: none;
    cursor: pointer;
  }

  p.history-empty {
    margin-top: 24px;
    color: #707070;
  }
  > section {
    display: flex;
    > section:first-child {
      max-width: 620px;
      width: 70vw;
      @media screen and (max-width: 680px) {
        width: 90vw;
      }
      /*  headerが100px, side menuが446pxあるので最低そのくらいは表示させる */
      height: calc(90vh - 100px);
      min-height: 446px;
      overflow: scroll;
      border-radius: 8px;
      background: #f5f5f5;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
      text-align: center;

      .blank {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #707070;
        opacity: 0.5;
        margin-top: 10vh;
      }
      .not-yet-received {
        width: 90%;
        margin: 0 auto 20px;
        h3 {
          font-weight: 500;
          font-size: 14px;
          text-align: left;
          color: #ff6030;
          margin: 10px 0;
        }
        ul {
          list-style: none;
          li {
            margin: 8px 0 0;
            &:first-child {
              margin: 0;
            }
            display: flex;
            background: #fff;
            filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.16));
            border-radius: 8px;

            .left-bar {
              width: 8px;
              border-radius: 8px 0px 0px 8px;
              background: #ff6030;
            }

            > div {
              width: 100%;
              padding: 20px 0;
              display: flex;
              .left-column {
                margin-left: 2.5%;
                width: 35%;

                p {
                  font-variant-numeric: tabular-nums;
                  font-family: Manrope;
                  font-weight: 500;
                  font-size: 12px;
                  letter-spacing: -0.02em;
                  line-height: 22px;
                  text-align: left;
                  color: #2f374e;
                }
                dl {
                  display: flex;
                  @media screen and (max-width: 680px) {
                    display: block;
                  }
                  dt {
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: right;
                    @media screen and (max-width: 680px) {
                      text-align: left;
                    }
                    color: #2f374e;
                    margin-right: 10px;
                    margin-top: 4px;
                    margin-bottom: 2px;
                  }

                  dd {
                    font-variant-numeric: tabular-nums;
                    font-family: Manrope;
                    font-weight: 500;
                    font-size: 18px;
                    letter-spacing: -0.02em;
                    line-height: 24px;
                    text-align: left;
                    color: #2f374e;
                    @media screen and (max-width: 680px) {
                      margin-left: 2em;
                    }
                  }
                }
              }

              .center-column {
                margin-left: 5%;
                @media screen and (max-width: 880px) {
                  margin-left: 0;
                }
                margin-top: 22px;
                width: 27%;
                @media screen and (max-width: 580px) {
                  width: calc(100% - 0.5% - 35% - 5%);
                }
                min-width: 120px;
                div {
                  margin-left: 2px;
                  display: flex;
                  justify-content: space-between;
                }
                hr {
                  border: 1px solid #707070;
                  margin: 5px 0;
                }
                p {
                  font-variant-numeric: tabular-nums;
                  font-family: Manrope;
                  font-weight: 500;
                  font-size: 14px;
                  letter-spacing: -0.02em;
                  line-height: 14px;
                  text-align: left;
                  color: #2f374e;
                }

                p.fee {
                  margin-top: 4px;
                  font-size: 12px;
                }
              }

              .right-column {
                position: relative;
                width: calc(100% - 0.5% - 35% - 5% - 27%);
                @media screen and (max-width: 580px) {
                  width: 0;
                }
                img.payment-method,
                button.detail {
                  position: absolute;
                  top: 45%;
                  @media screen and (max-width: 580px) {
                    top: 55%;
                  }
                }

                img.payment-method {
                  display: block;
                  right: calc(20% + 60px);
                  @media screen and (max-width: 580px) {
                    right: calc(10vw + 60px);
                  }
                }

                button.detail {
                  right: 10%;

                  @media screen and (max-width: 580px) {
                    right: 5vw;
                  }
                }
              }
            }
          }
        }
      }

      hr {
        border: 1px solid #cbcbcb;
        opacity: 0.5;
        margin: 0;
      }

      .histories {
        margin-top: 20px;
        .summary {
          > p {
            font-variant-numeric: tabular-nums;
            font-family: Manrope;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: -0.02em;
            line-height: 22px;
            text-align: center;
            color: #2f374e;
            margin-bottom: 15px;
          }

          > div {
            display: flex;
            justify-content: space-between;
            width: 38%;
            @media screen and (max-width: 580px) {
              width: 50%;
            }
            margin: 0 auto 20px;
            > p {
              font-weight: bold;
              font-size: 16px;
              line-height: 29px;
              text-align: left;
              color: #2f374e;
              margin-top: 8px;
              width: 78px;
            }
            > div {
              display: flex;
              justify-content: right;
              p:first-child {
                font-variant-numeric: tabular-nums;
                font-family: Manrope;
                font-weight: 500;
                font-size: 26px;
                letter-spacing: -0.02em;
                line-height: 38px;
                text-align: right;
                color: #2f374e;
              }
              p:last-child {
                margin-left: 1px;
                font-weight: bold;
                font-size: 16px;
                line-height: 46px;
                text-align: left;
                color: #2f374e;
              }
            }
          }
        }
        ul {
          list-style: none;
          li {
            display: flex;
            width: 100%;
            height: 100px;
            padding: 20px 30px;
            position: relative;
            background: #fff;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

            .date-and-amounts {
              width: 80%;
              .date-and-confirm-no {
                font-variant-numeric: tabular-nums;
                font-family: Manrope;
                font-weight: 500;
                font-size: 12px;
                letter-spacing: -0.02em;
                line-height: 18px;
                text-align: left;
                color: #2f374e;
              }
              .amount-and-condition {
                display: flex;
                justify-content: left;
                .amount {
                  display: flex;
                  margin-right: 14px;
                  > p:nth-child(1) {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: left;
                    color: #2f374e;
                    margin-top: 4px;
                    width: 50px;
                  }

                  > div {
                    width: 80px;
                    display: flex;
                    justify-content: right;
                    p:nth-child(1) {
                      font-variant-numeric: tabular-nums;
                      font-family: Manrope;
                      font-weight: 500;
                      font-size: 18px;
                      letter-spacing: -0.02em;
                      line-height: 24px;
                      text-align: right;
                      color: #2f374e;
                    }
                    p:nth-child(2) {
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      text-align: left;
                      color: #2f374e;
                      margin-top: 2px;
                    }
                  }
                }
              }
            }

            img.payment-method,
            button.detail {
              position: absolute;
              top: 35px;
              @media screen and (max-width: 620px) {
                top: 15px;
              }
            }

            img.payment-method {
              display: block;
              position: absolute;
              right: calc(60px + 8%);
            }

            button.detail {
              right: 4%;
            }
          }
        }
      }
    }
  }
  footer {
    margin-top: 50px;
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    color: #fff;

    background-color: unset;
    position: unset;
  }
}
