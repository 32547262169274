$standardInnerWidth: 220px;

.submit-button {
  button {
    background: #ffcf43;

    background: #d3d3d3;
    border: none;
    border-radius: 30px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.16);
    color: #fff;

    cursor: not-allowed;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    height: 60px;
    line-height: 60px;

    max-width: 100%;
    text-align: center;
    width: $standardInnerWidth;

    &.active {
      background: #ffcf43;
      color: #0d363c;
      cursor: pointer;
    }
  }
}
