#login.cria  {
  @media screen and (max-width: 960px) {
    header.navbar {
      section.forms {
        img.logo {
          height: 57px;
          margin-top : 25px; 
          margin-right: 5vw; 
        }
      }
    }
  }
}
#login.prekyu {
  @media screen and (max-width: 960px) {
    header.navbar {
      section.forms {
        img.logo {
          height: 45px;
          margin-top : 30px; 
          margin-right: -5vw; 
        }
      }
    }
  }
}

#login {
  header.navbar {
    section.forms {
      div.limit {
        div.limit-date {
          p:first-child {
            width: 70px;
            font-variant-numeric: tabular-nums;
            font-family: Manrope;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: -0.02em;
            line-height: 22px;
            text-align: left;
            color: #fff; }
          p:last-child {
            width: 88px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-align: left;
            color: #fff; } }
        div.upper-limit {
          width: fit-content;
          span.amount {
            font-variant-numeric: tabular-nums;
            font-family: Manrope;
            font-weight: 500;
            font-size: 42px;
            letter-spacing: -0.02em;
            line-height: 58px;
            text-align: right;
            color: #fff; 
          }
          span.yen {
            font-weight: bold;
            font-size: 20px;
            line-height: 46px;
            text-align: left;
            color: #fff; } } }
      div.reload {
        position: relative;
        img.loading{
          position: absolute;
          width: 70px;
          height: 70px;
          border : 1px solid black;
          top: -5px;
        }
        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #fff; }
        img {
          width: 30px;
          height: 30px; 
          cursor: pointer;
        }
      } 
    }

    section.right-menu {
      p:first-child {
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        text-align: left;
        color: #fff; }
      p:last-child {
        width: 300px;
        height: 44px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.3);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding: 10px;
        text-align: left;
        color: #fff; } } }

  @media screen and (min-width: 960px) {
    header.navbar {
      padding-top: 15px;
      padding-bottom: 20px;
      display: flex;
      width: fit-content;
      margin : 0 auto;
      section.forms {
        margin-top: 10px;
        width: 620px;
        display: flex;
        img.logo {
          display: block;
          height: 57px;
          background: transparent; 
        }

        div.limit {
          height : 55px;
          width: 286px;
          margin-left: 100px;
          display: flex;
          justify-content: space-between;
          div.limit-date {
            margin-top: 10px;
          }
          div.upper-limit {
            span:last-child {
              margin-left: 2px; 
            } 
          } 
        }
        div.reload {
          margin-left: 10px;
          p {
            margin-bottom: 2px; 
          }
        } 
      }

      section.right-menu {
        width: 300px;
        margin-left: 20px;
        background: transparent;

        p:first-child {}
        p:last-child {} } } }

  @media screen and (max-width: 960px) {
    header.navbar {
      margin: 0 auto;
      max-width: 620px;
      width: 70vw;
      @media screen and (max-width: 680px) {
        width: 90vw; }
      padding: 0;
      display: flex;
      justify-content: space-between;

      section.forms {
        display: flex;
        justify-content: space-between;
        width : 100%;
        img.logo {
          display: block;
          background: transparent;
        }

        div.limit {
          margin-top : 13px;
          margin-right : -20px;
          div.limit-date {
            display: flex;
            p:first-child {}
            p:last-child {
              margin-left: 6px;
              margin-top: 2px; } }
          div.upper-limit {
            text-align: right;
            span:first-child {}
            span:last-child {
              margin-left: 2px; } } }

        div.reload {
          margin-right : 20px;
          margin-top : 30px;
          p {
            margin-bottom: 2px; }
          img {} } }

      section.right-menu {
        margin-top : 15px;
        p:first-child {}
        p:last-child {} } } }

  @media screen and (max-width: 680px) {
    header.navbar {
      position: relative;
      display: block;
      section.forms {
        width: 100%;
        margin-top: 66px;
        justify-content: space-between;
        div.limit {
          margin-right: 2vw;
          div.limit-date {
            margin-top: 4px;
            p:first-child {}
            p:last-child {} }
          div.upper-limit {
            margin-top: -12px;
            margin-left: 16px;
            span:first-child {}
            span:last-child {} } }

        div.reload {
          margin-right: 0;
          p {}
          img {} } }

      section.right-menu {
        margin-top: 16px;
        position: absolute;
        top: -68px;
        left : 0;
        p:first-child {}
        p:last-child {} } } } }

