.prekyu div#usage-seven-atm{
  section{
    main{
      ul{
        li{
          div:first-child{
            h4{
              color: #F15A22;
            }
          }
        }
      }
    }
  }
}

div#usage-seven-atm{
  section{
    background: #F5F5F5;
    main{
      padding: 0;
      margin: 0 auto;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
      h2{
        background: #fff;
        div{
          max-width: 940px;
          width: 80vw;
          margin: 15px auto;
          display: flex;
          img{
            width: 114px;
            height: 130px;
            background: transparent;
          }
          p{
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            text-align: left;
            color: #2F374E;
          }
        }
      }
  
      h3{
        display: block;
        max-width: 940px;
        width: 80vw;
        margin : 35px auto 18px;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-align: left;
        color: #2F374E;
      }
      ul{
        padding-bottom: 65px;
        li{
          display: flex;
          margin: 0 auto 20px;
          padding: 35px 0 25px;
          @media screen and (max-width: 760px) {
            padding: 25px 0 15px;
          }
          max-width: 940px;
          width: 80vw;
          @media screen and (max-width: 760px) {
            width: 90vw;
          }
          border-radius: 8px;
          background: #fff;
          div:first-child{
            margin: 7px 7%;
            width: 48%;
            h4{
              font-weight: bold;
              font-size: 20px;
              line-height: 26px;
              text-align: left;
              color: #00B9B2;
            }
  
            p{
              font-weight: bold;
              font-size: 16px;
              line-height: 26px;
              text-align: left;
              color: #2F374E;
            }
          }
          div:last-child{
            width: 33%;
            height: fit-content;
            img{
              display: block;
              width: 100%;
            }
          }
          &:last-child{
            margin-bottom: 0 ;
          }
          @media screen and (max-width: 760px) {
            div:first-child{
              width: 38%;
            }
            div:last-child{
              width: 43%;
            }
          }
        }
      }
    }
  }
  footer{
    margin-top : 0;
  }
}
