.home-pincode {
  >section {
    margin: 30px auto 0;
    text-align: center;
    h2 {
      &.error {
        color: #FF6030; } }

    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #2F374E; }

    div.submit-button {
      text-align: center;
      margin: 20px auto 0; }

    div.whiteout-button {
      text-align: center;
      top: calc(100% + 20px);
      position: absolute;
      left: 0;
      width: 100%; }

    p.reset-pincode {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #2F374E;
      position: relative;
      margin : 30px auto 50px;
      cursor: pointer;

      img {
        margin-left: 10px; } } } }


.home-pincode-reset {
  max-width: 620px;
  width: 90vw;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #707070;
  margin: 10vh auto;
  position: relative;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #2F374E;
    margin-top: 30px;
    margin-bottom: 30px; }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #2F374E;
    margin-bottom: 70px; }
  div.whiteout-button {
    text-align: center;
    width: 100%;
    margin-bottom: 70px;
    button {
      color: #2F374E;
      border: 1px solid #707070; } }

  div.back-to-input {
    top: calc(100% + 70px);
    position: absolute;
    left: 0;
    width: 100%;
    div.whiteout-button {
      text-align: center;
      button {
        width: 160px;
        height: 40px;
        border-radius: 20px;
        background: transparent;
        border: 2px solid #fff;
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #fff; } } } }
