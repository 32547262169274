div#privacy-policy {
  >main {
    >section {
      display: block;
      >section {
        margin: 0 auto;
        >p {
          width: 100%;
          font-weight: medium;
          font-size: 12px;
          line-height: 22px;
          text-align: left;
          color: #2F374E; }

        >h3 {
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
          text-align: left;
          color: #2F374E; } 
      } 
    } 
    section#right-menu-links {
      display: none;
      @media screen and (max-width: 960px) {
        display: block;
      }
    }
  } 
}
  

