$standardInnerWidth: 220px;

.mini-submit-button {
  button {
    background: none;
    box-shadow: none;
    border: none;
    border-radius: 15px;
    color: #bbb;

    cursor: not-allowed;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;

    max-width: 100%;
    text-align: center;
    width: $standardInnerWidth;

    &.active {
      // background: #ffcf43;
      color: #0d363c;
      cursor: pointer;
    }
  }
}
