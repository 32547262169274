.notification-detail {
  max-width: 620px;
  width: 90vw;
  min-height: 387px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #707070;
  margin: 5vw auto;
  @media screen and (max-width: 540px) {
    margin-top: calc((100vh - 387px) / 2);
  }
  position: relative;

  section {
    width: 75%;

    margin: 5% auto;

    h2 {
      color: #0d363c;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      margin-bottom: 8px;
    }

    p.date {
      color: #707070;
      display: block;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 16px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
    }
  }
}
