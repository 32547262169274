.prekyu main#tutorial {
  > div.carousel {
    h2 {
      background: #F15A22;
    }
    .carousel.carousel-slider {
      ul.control-dots {
        li.dot {
          background-image: url('/img/tutorial/prekyu/not-selected.png');
        }
        li.dot.selected {
          background-image: url('/img/tutorial/prekyu/selected.png'); 
        } 
      } 
    }
  }
}
.cria main#tutorial {
  > div.carousel {
    .carousel.carousel-slider {
      ul.control-dots {
        li.dot {
          background-image: url('/img/tutorial/cria/not-selected.png');
        }
        li.dot.selected {
          background-image: url('/img/tutorial/cria/selected.png'); 
        } 
      } 
    }
  }
}

main#tutorial {
  background: #2f374e;
  border: 1px solid #707070;
  height: 100vh;
  opacity: 0.9;
  padding-top: 110px;
  @media screen and (max-width: 640px) {
    padding-top: 10vh;
  }

  > div.carousel {
    background: #fff;
    border-radius: 8px;
    height: 38.88vw;
    margin: 0 auto;
    max-height: 446px;
    max-width: 780px;
    overflow: visible;
    width: 68vw;

    @media screen and (max-width: 640px) {
      width: 90vw;
      height: 51.5vw;
    }
    &.last-page{
      @media screen and (max-width: 640px) {
        height: 280px;
      }
      .carousel.carousel-slider {
        ul.control-dots {
          @media screen and (max-width: 640px) {
            top: calc(280px + 20px);
          }
        }
      }
    }

    .carousel.carousel-slider {
      overflow: visible;

      ul.control-dots {
        top: calc(38.88vw + 20px);
        @media screen and (min-width: 1100px) {
          top: calc(100% + 20px); }
        @media screen and (max-width: 640px) {
          top: calc(51.5vw + 20px);
        }

        li.dot {
          height: 10px;
          opacity: 1;
          width: 10px; 
        }
      } 
    }

    h2 {
      background: #00B9B2;
      color: #fff;
      font-size: 21px;
      font-weight: bold;
      height: 30px;
      line-height: 33px;
      margin: 90px auto 20px;
      text-align: center;
      @media screen and (max-width: 640px) {
        margin: 4vh auto 4vh;
      }
    }

    p {
      color: #2f374e;
      font-size: 18px;
      font-weight: 300;
      line-height: 30px;
      text-align: center; }

    &:nth-child(3) {
      margin-bottom: 18px; 
    } 
  }

  .whiteout-button,
  .submit-button {
    margin: 80px auto 40px;
    text-align: center; 
  } 
}


