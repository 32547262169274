#language-selector {
  background: transparent;
  border: none;
  box-shadow: none;
  display: flex;
  height: 28px;
  margin: 0 8px 1px 0;
  margin: none;
  padding: 7px 18px;
  > div {
    color: #fff;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1;
    text-align: left; }

  > img {
    background: transparent;
    height: 8px;

    margin: 4px 0 0 12px;
    width: 8px; } }



.language-pulldowns {
  bottom: auto;
  padding: 0;
  position: absolute;
  right: 5vw;
  top: 10%;
  width: 190px;
  @media screen and (min-width: 960px) {
    right: 25vw; }

  @media screen and (max-width: 580px) {
    left: calc((100vw - 190px) / 2); }


  ul {
    background: #fff;
    border-radius: 8px;
    list-style: none;
    text-align: left;
    > li {
      border-bottom: 0.5px solid #707070;
      cursor: pointer;
      display: flex;
      height: 50px;
      > div {
        color: #2f374e;
        font-size: 14px;
        font-weight: normal;
        height: 50px;
        line-height: 50px;
        margin-left: 56px;
        &.selected {
          margin-left: 0; } }


      > img {
        display: block;
        height: 9px;
        margin-left: 20px;
        margin-right: calc(56px - 13px - 20px);
        margin-top: calc((50px - 9px) / 2);
        width: 13px; }

      &:last-child {
        border-bottom: none; } } } }





@media screen and (max-width: 760px) {
  header#login {
    padding-top: 70px; } }



@media screen and (max-width: 460px) {
  #language-selector {
    background: transparent;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    height: 19px;
    line-height: 18px;
    text-align: left;
    width: 79px; } }


