.prekyu section#right-menu-links {
  section.buttons,section.helps {
    ul {
      li {
        hr {
          border-bottom : 1px solid #F15A22;
        }
      }

      li.selected {
        p {
          color: #F15A22; 
        }
      }
    }
  }
}

section#right-menu-links {
  transition: all 0.2s;

  width: 300px;
  margin-left: 20px;
  z-index: 15;

  section.buttons,section.helps {
    ul {
      border-radius: 8px;
      background: #fff;
      list-style : none;
      li {
        display: flex;
        justify-content: left;
        height: 50px;
        position: relative;
        img:first-child {
          margin-top: 10px;
          margin-left: 15px;
          width: 30px;
          height: 30px; }
        p {
          margin-top: 15px;
          margin-left: 15px;
          font-weight: 500;
          font-size: 14px;
          text-align: left;
          color: #2F374E; }
        img.new-arrival {
          width: 10px;
          height: 10px;
          position:absolute;
          left: 34px;
          top: 10px;
        }
        p.new-arrival {
          width: 95px;
          height: 20px;
          border-radius: 10px;
          background: #fff;
          border: 1px solid #FF6030;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #FF6030;
        }

        img:last-child {
          width: 14px;
          height: 16px;
          position: absolute;
          top: 15px;
          right: 20px; }
        hr {
          width: 100%;
          position: absolute;
          top: 35px;
          border-bottom : 1px solid #00B9B2;
          opacity: 0.1; } }

      li.selected {
        p {
          color: #00B9B2; 
        } 
        p.new-arrival {
          color: #FF6030;
        }
      }
      li.seven {
        p {
          margin-top: 5px; } } } }

  section.helps {
    margin-top : 20px; }

  h3 {
    margin-top: 20px;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    color: #fff; }

  p {
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: left;
    color: #fff; }

  .whiteout-button {
    display: none;
    visibility: hidden;
    width: 100%;
    text-align: center;
    button {
      width: 140px;
      height: 40px;
      border-radius: 20px;
      background: transparent;
      border: 2px solid #fff;
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #fff; } } }

@media screen and (max-width: 960px) {
  .prekyu section#right-menu-links {
    background: #554826;
  }
  .cria section#right-menu-links {
    background: #2D6179;
  }
  body {
    overflow-x: hidden; 
  }
  section#right-menu-links {
    position: fixed;
    top: 0;
    width: 260px;
    height: 100%;
    padding: 20px;
    z-index: 15;

    &.opened {
      section.buttons,section.helps {
        ul {
          width: 220px;
          li {
            img:first-child {}
            p {}
            img:last-child {
              right: 10px; }
            hr {} }

          li.selected {
            p {} }
          li.seven {
            p {} } } }

      .whiteout-button {
        display: block;
        visibility: visible; } } } }

.cria button#right-menu-open-button {
  @media screen and (max-width: 960px) {
    background: #1D6A75;
  }
}

.prekyu button#right-menu-open-button {
  @media screen and (max-width: 960px) {
    background: #554826;
  }
}

button#right-menu-open-button {
  display: none;
  visibility: hidden;
  z-index: 15;
  @media screen and (max-width: 960px) {
    display: block;
    visibility: visible;
    width: 80px;
    height: 60px;
    border-radius: 4px 0px 0px 4px;
    position: fixed;
    right: 0;
    top: 20px;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 10px 15px;
    img {
      width: 32px;
      height: 24px;
      display: block; }

    p {
      font-variant-numeric: tabular-nums;
      font-family: Manrope;
      font-weight: bold;
      font-size: 8px;
      letter-spacing: -0.02em;
      line-height: 18px;
      text-align: left;
      color: #fff; }

    &.closed {
      display: none;
      visibility: hidden; } } }
