.right-allow-link {
  span {
    color: #fff;
    cursor: pointer;
    display: block;

    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    margin: 0 auto;
    padding-right: 16px;
    position: relative;
    text-align: center;
    width: fit-content;

    &:after {
      background-image: url('../../img/allow.svg');
      content: '';
      height: 9px;
      position: absolute;
      right: 0;
      top: 8px;
      width: 6px; } } }



