.prekyu .home-result-bank,.home-result-seven h2 p{
  font-family: 'Noto Sans CJK JP';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  
  color: #F15A22;
}

.cria .home-result-bank,.home-result-seven h2 p{
  color: #00B9B2; 
}

.home-result-bank,.home-result-seven {
  >section {
    h2 {
      p {
        color: #F15A22; 
        }
    }
  }
}

.home-result-bank,.home-result-seven {
  max-width: 460px;
  width: 90vw;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #707070;
  margin: 10vh auto;
  position: relative;
  >section {
    width: 60%;
    @media screen and (max-width: 540px) {
      width: 65%; }
    @media screen and (max-width: 480px) {
      width: 90%; }
    margin: 50px auto 25px;
    h2 {
      display: flex;
      margin: 30px auto 10px;
      width: fit-content;
      img {
        width: 55.29px;
        height: 55.29px;
        background: transparent;
        margin-right: 15px; }
      p {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        } }
    >p {
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      color: #2F374E;
      margin-top : 20px; }

    div.whiteout-button {
      text-align: center;
      top: calc(100% + 20px);
      position: absolute;
      left: 0;
      width: 100%;
      button {
        width: 160px;
        height: 40px;
        border-radius: 20px;
        background: transparent;
        border: 2px solid #fff;
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #fff; } } } }

