.prekyu .company-pulldowns {
  ul {
    li {
      &.selected {
        &:after {
          background-image: url('../../img/company-selector/prekyu/selected.svg');
        }
      }
    }
  }
}

.company-pulldowns {
  width: 380px;
  top: 10%;
  left: calc((100vw - 940px) / 2 + 620px + 20px - 80px);
  @media screen and (max-width: 960px) {
    left: calc(100vw - 380px - 5vw); 
  }
  @media screen and (max-width: 680px) {
    left: calc(100vw - 380px - 5vw); 
  }

  @media screen and (max-width: 580px) {
    width: 80vw;
    left: 10vw; 
  }

  right: 15%;
  bottom: auto;
  padding: 0;
  position: absolute;

  ul {
    list-style: none;
    border-radius: 8px;
    background: #fff;
    text-align: left;
    overflow-y: hidden;

    li {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      color: #2F374E;
      height : 56px;
      padding: 15px 15%;
      white-space: nowrap;
      text-align: left;
      border-bottom: 1px solid #CBCBCB;
      position: relative;
      cursor: pointer;
      overflow-x: auto;
      overflow-y: hidden;

      &:first-child {
        height : 50px;
        padding: 13px 10%;
        white-space: nowrap;
        text-align: left;

        &:after {
          content: "";
          background-image: url('../../img/company-selector/close.svg');
          background-repeat: no-repeat;
          position: absolute;
          width: 13px;
          height: 10px;
          top: 18px;
          right: 30px; } }

      &.selected {
        font-weight: bold;
        &:after {
          content: "";
          background-image: url('../../img/company-selector/cria/selected.svg');
          background-repeat: no-repeat;
          position: absolute;
          width: 13px;
          height: 10px;
          top: 23px;
          left: 20px; } } } } }

button#company-selector {
  width: 300px;
  @media screen and (max-width: 960px) {
    max-width: 300px;
    width: 30vw; }
  @media screen and (max-width: 680px) {
    max-width: 360px;
    width: 60vw; }
  height: 44px;
  overflow: hidden;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.3);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 10px 45px 10px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  color: #fff;
  position: relative;

  &:after {
    content: "";
    background-image: url('../../img/company-selector/open.svg');
    background-repeat: no-repeat;
    position: absolute;
    width: 13px;
    height: 10px;
    top: 18px;
    right: 30px; } }

