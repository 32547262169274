$standardWidth: 300px;
$standardInnerWidth: 220px;

main#login-screen {
  padding: 0px;

  .contents-inner {
    color: #fff;
    max-width: 460px;
    padding: 0;
    text-align: center;

    form {
      padding: 0 15px;

      @media screen and (min-width: 980px) {
        padding: 0 35px; }


      ul.number-and-password {
        display: block;
        list-style: none;
        margin: 0 auto;
        text-align: left;
        width: $standardWidth;
        li {
          display: block;
          margin-top: 16px; }


        @media screen and (max-width: 460px) {
          width: $standardInnerWidth; } }



      input.mail-phone {
        padding-top: 1rem; }


      .error {
        color: #cc0000;
        font-size: 13px;
        padding-top: 1rem;
        text-align: center; }


      @media screen and (min-width: 768px) {
        .error {
          padding-top: 0;
          text-align: left; } } }




    ul.info {
      display: flex;
      justify-content: space-between;
      list-style: none;

      @media screen and (max-width: 460px) {
        display: block;
        margin: 0 auto;
        li {
          margin: 0 auto 16px; } }



      li {
        background: transparent;
        border: 1px solid #fff;
        border-radius: 6px;
        cursor: pointer;
        height: 70px;
        padding-left: 24px;
        padding-top: 12px;
        text-align: left;
        width: $standardInnerWidth; }


      h3 {
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: left; }


      div {
        display: flex;
        justify-content: left;
        margin-top: 4px; }


      div img {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));
        height: 26px;
        margin-left: -4px;
        width: 20.8px; }


      div p {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        line-height: 26px;
        text-align: left; } }

    button {
      margin: 32px 0; } } }



