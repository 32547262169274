.accounting-history-card{
  background: #FFFFFF;
  &.hover{
    background: #E8FCFB;
  }

  &.next-opened{
    background: linear-gradient(180deg , #FFFFFF , 95%, #E0E0E0);
  }
  overflow :hidden;
  border-bottom: 1px solid #D3D3D3;

  .receive-date,.caution{
    .alt-icon{
      width: calc( 100% * 32 / 360 );
      @media screen and (max-width: 480px) {
        width: calc( 100% * 45 / 360 );
      }
    }
  }

  .receive-date{
    margin-top: 4px; 
    display: flex;
    justify-content: space-between;
    padding: 0 16px ;
    @media screen and (max-width: 480px) {
      padding: 0 8px;
    }
    p {
      width: calc( 100% * 140 / 360 );
      @media screen and (max-width: 480px) {
        width: calc( 100% * 120 / 360 );
      }
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #959595;
      text-align: left;
      margin-top: 8px;
    }
    .alt-amount{
      width: calc( 100% * (360 - 32 - 140 - 12) / 375 );
      @media screen and (max-width: 480px) {
        width: calc( 100% * (360 - 50 - 120 - 12) / 360 );
      }
    }
    .alt-no-expand{
      width: 12px;
      height: 5px;
    }
  }
  .upper-area{
    display: flex;
    padding: 0 16px 16px;
    @media screen and (max-width: 480px) {
      padding: 0 8px 16px;
    }
    justify-content: space-between;
    .icon{
      width: calc( 100% * 32 / 360 );
      @media screen and (max-width: 480px) {
        width: calc( 100% * 45 / 360 );
      }
      img{
        width : 40%;
        display: block;
        margin: 10px auto;
        @media screen and (max-width: 480px) {
          margin: 8px auto;
        }
      }
    }
    .description{
      width: calc( 100% * 140 / 360 );
      @media screen and (max-width: 480px) {
        width: calc( 100% * 120 / 360 );
      }
      h2{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #2F374E;
        text-align: left;
      }

      .account-number {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        color: #2F374E;
        text-align: left;
      }

    }
    .amount{
      width: calc( 100% * (360 - 32 - 140 - 12) / 375 );
      @media screen and (max-width: 480px) {
        width: calc( 100% * (360 - 50 - 120 - 12) / 360 );
      }
      text-align: right;
      .changedAmount{
        padding-right: 6px;
        display: flex;
        justify-content: right;
        line-height: 30px;
        img{
          display: block;
        }
        
        img.plus{
          // 10px
          margin: 0 8px 0 0;
        }
        img.minus{
          // 16px
          margin: 0 4px 0 0;
        }
        .kingaku{
          margin: 0 4px 0 0;
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          letter-spacing: -0.04em;
          color: #2F374E;
        }
        .yen{
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          letter-spacing: -0.04em;
          height: 14px;
          margin-top: 4px;
        }
      }
      .balance{
        padding-right: 6px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #5A5A5A;
      }

      .status-card{
        padding-right: 6px;
        margin-top: 9px;
        width: 100%;
        display: flex;
        justify-content: right;
        >div{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          text-align: center;
          color: #2F374E;
          border: 1px solid #2F374E;

          width: 80px;

          &.negative{
            background: #F5F5F5;
          }
          &.expiration-date{
            border: none;
            color: #E20303;
          }
        }
      }

      .expiration-date{
        padding-right: 6px;
        margin-top: 9px;
        width: 100%;
        display: flex;
        justify-content: right;
        >div{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          text-align: center;
          color: #E20303;
        }
      }

      .fees{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
      }
    }

    .expand{
      img{
        display: block;
        width: 12px;
        height: 5px;
        position: relative;
        top: calc((100% - 5px) / 2);
      }
    }
    .no-expand{
      width: 12px;
      height: 5px;
    }
  }
  .caution{
    display: flex;
    justify-content: space-between;
    padding: 0 16px ;
    margin: -8px 0 12px;
    @media screen and (max-width: 480px) {
      padding: 0 8px;
    }
    .caution-message {
      width: calc( (100% * 140 / 360) + (100% * (360 - 32 - 140 - 12) / 375) + 12px );
      @media screen and (max-width: 480px) {
        width: calc( (100% * 120 / 360) + (100% * (360 - 50 - 120 - 12) / 360) + 12px);
      }
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 150%;
      color: #E20303;
      text-align: left;
    }
  }

  .expand-area{
    max-height: 0;
    transition : .4s;
    &.opened-bank{
      max-height: 430px;
    }
    &.opened-seven{
      max-height: 430px;
    }
  }
}

