.prekyu .home-confirm,.prekyu .home-pincode {
  >section {
    h2 {
      color: #F15A22; 
    }
  }
}

.home-confirm,.home-pincode {
  max-width: 620px;
  width: 90vw;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #707070;
  margin: 10vh auto;
  @media screen and (max-width: 480px) {
    margin: 4vh auto;
  }
  position: relative;
  >section {
    width: 55%;
    @media screen and (max-width: 540px) {
      width: 65%; }
    @media screen and (max-width: 480px) {
      width: 90%; }
    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #00B9B2; 
    }

    div.whiteout-button {
      text-align: center;
      top: calc(100% + 20px);
      position: absolute;
      left: 0;
      width: 100%;
      button {
        width: 160px;
        height: 40px;
        border-radius: 20px;
        background: transparent;
        border: 2px solid #fff;
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #fff; } } } }


