ul.bank-detail {
  list-style: none;
  >li {
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      >li {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: #2F374E;
        width: calc(50% - 10px); }

      >li:first-child {
        text-align: right;
        margin-right: 5px; }
      >li:last-child {
        text-align: left;
        margin-left: 5px; } } } }

