div#terms {
  >main {
    >section {
      display: block;
      >section {
        margin: 0 auto;
        p.term-first-sentence{
          margin-bottom: 16px;
        }

        @media screen and (max-width: 480px) {
          div.term-title {
            display: block;
          }
        }
        div.term-title {
          margin-bottom: 30px;
          p {
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            text-align: left;
            color: #2F374E;
            margin-top : 13px; 
          } 
        }

        >h3 {
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
          text-align: left;
          color: #2F374E; } 

        p {
          font-weight: medium;
          font-size: 12px;
          line-height: 22px;
          color: #2F374E; 
        }
      } 

      >section {
      }
    }
    section#right-menu-links {
      display: none;
      @media screen and (max-width: 960px) {
        display: block;
      }
    }
  } 
}

