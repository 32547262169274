.terms-of-use-modal {
  &__button-field {
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 680px) {
      height: 160px;
      flex-direction: column-reverse;
    }
  }
}
