.password {
  position: relative;

  img {
    height: 21px;
    position: absolute;
    right: 5%;
    top: calc(4px + 50px - 21px / 2);
    width: 31px; }

  .mask {
    font-family: text-security-disc; }

  div {
    display: flex;
    justify-content: left;
    p:last-child {
      font-size: 12px;
      height: 12px;
      line-height: 12px;
      margin-left: 4px;
      margin-top: 10px; } } }

@font-face {
  font-family: text-security-disc;
  src: url("./text-security-disc.ttf"); }
