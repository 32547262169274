main#notification-list {
  padding: 0;
  margin: 0 auto;
  width: fit-content;

  >section {
    display: flex;
    >section:first-child {
      max-width: 620px;
      width: 70vw;
      @media screen and (max-width: 680px) {
        width: 90vw; }
      ul.tabs {
        list-style: none;
        display: flex;
        margin-bottom: 1px;
        li {
          width : 50%;
          height: 40px;
          p {
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #fff;
            margin-top: calc((40px - 22px) / 2); }

          &.selected {
            border-radius: 8px 8px 0px 0px;
            background: #fff;
            position: relative;
            p {
              color: #2F374E; }

            div {
              position: absolute;
              bottom: 0;
              height: 4px;
              background: #FFCF43;
              width : 100%; } } } }

      section {
        /*  headerが100px, side menuが446pxあるので最低そのくらいは表示させる */
        max-height: calc(90vh - 100px);
        border-radius: 0px 0px 8px 8px;
        background: #F5F5F5;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
        text-align : center; }

      section.notifications {
        ul.notifications {
          list-style: none;
          height: calc(90vh - 100px);
          min-height: 446px;
          background: #F5F5F5;
          overflow: scroll;
          li {
            height: 90px;
            display: flex;
            padding: 15px;
            position: relative;
            background: #fff;
            filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
            div.no-contents {
              font-weight: bold;
              font-size: 14px;
              line-height: 22px;
              text-align: center;
              color: #707070;
              opacity: 0.5; }
            img {
              display: block;
              width: 40px;
              height: 40px;
              margin-right: 20px; }
    
            div {
              h3,p {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                text-align: left;
                color: #2F374E; } }
            p.date {
              font-weight: 500;
              font-size: 12px;
              line-height: 24px;
              text-align: right;
              color: #707070;
              right: 15px;
              bottom: 15px;
              position: absolute; }

            &:last-child {
              border-radius: 0px 0px 8px 8px; 
            } 
            &.loading {
              text-align:center;
              img{
                margin:0 auto;
              }
            }
          } 
        } 
      } 
    } 
  }

  footer {
    margin-top : 50px;
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    color: #fff;

    background-color: unset;
    position: unset; } }


