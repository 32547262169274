.home-confirm {
  > section {
    margin: 50px auto 25px;
    div.prepayment-apply-details {
      width: 100%;
      margin: 0 auto;
      div.acceptable {
        display: flex;
        justify-content: center;

        > div:nth-child(1) {
          font-weight: bold;
          font-size: 16px;
          line-height: 29px;
          text-align: left;
          color: #2f374e;
          margin-top: 23px;
          margin-right: 8px;
        }

        > div:nth-child(2) {
          display: flex;
          justify-content: center;
          > div:nth-child(1) {
            min-width: 121px;
            font-variant-numeric: tabular-nums;
            font-family: Manrope;
            font-weight: 500;
            font-size: 42px;
            letter-spacing: -0.02em;
            line-height: 58px;
            text-align: right;
            color: #2f374e;
          }
          > div:nth-child(2) {
            width: 16px;
            height: 24px;
            font-weight: bold;
            font-size: 16px;
            line-height: 46px;
            text-align: left;
            color: #2f374e;
            margin-top: 12px;
          }
        }
      }

      hr {
        margin: 0 auto;
        height: 0px;
        background: transparent;
        border: 1px solid #707070;
      }

      div.apply {
        display: flex;
        justify-content: center;

        > div:nth-child(1) {
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          text-align: left;
          color: #2f374e;
          margin-top: 1px;
          margin-right: 8px;
        }

        > div:nth-child(2) {
          font-variant-numeric: tabular-nums;
          font-family: Manrope;
          font-weight: 500;
          font-size: 18px;
          letter-spacing: -0.02em;
          line-height: 30px;
          text-align: left;
          color: #2f374e;
        }

        > div:nth-child(3) {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #2f374e;
          margin-top: 8px;
        }
      }
    }

    h3 {
      margin-top: 30px;
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 29px;
      text-align: left;
      color: #2f374e;
    }

    div.payment-method-seven {
      display: flex;
      justify-content: center;
      background: #f5f5f5;
      padding: 40px 0 50px;

      img:nth-child(1) {
        display: block;
        width: 51px;
        height: 49px;
        margin-top: 7px;
      }

      img:nth-child(2) {
        display: block;
        width: 43px;
        height: 63px;
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        color: #2f374e;
        margin-top: 9.5px;
      }
    }

    div.payment-method-bank {
      padding: 0 10px;
      margin: 10px 0 20px 0;
      div.transfer-date {
        display: flex;
        justify-content: space-between;
        p {
          font-weight: bold;
          font-size: 16px;
          line-height: 32px;
          text-align: left;
          color: #2f374e;
        }
      }
    }

    div.submit-button {
      text-align: center;
      margin: 20px auto 0;
    }
  }
}
