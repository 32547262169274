$standardInnerWidth: 220px;

.whiteout-button {
  button {
    background: transparent;
    border: 2px solid #fff;
    border-radius: 30px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.16);

    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    height: 60px;

    line-height: 60px;

    max-width: 100%;
    text-align: center;
    width: $standardInnerWidth; 
  } 
}

.whiteout-button.disabled {
  button {
    border: 2px solid #d3d3d3;
    color: #d3d3d3;
  }
}
