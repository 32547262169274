@charset "UTF-8";
/*cria-web-document*/
/*normalize document*/
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,600");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, let,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-weight: normal;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

*:focus {
  outline: none;
}

/* base color document */
html {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

.fwb {
  font-weight: bold;
}

.ls1 {
  letter-spacing: 1px;
}

.black {
  color: #3d5e63;
}

a {
  color: #4AC2B8;
  text-decoration: none;
}

a:hover {
  color: #37a299;
}

a.link-icon {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  font-size: 15px;
}

@media screen and (min-width: 768px) {
  a.link-icon {
    font-size: 16px;
  }
}

a.link-icon:before {
  content: "";
  background: url(../img/icon-arrow.svg) no-repeat;
  width: 23px;
  height: 23px;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  margin: auto 0;
  position: absolute;
}

.warning {
  color: #D67373;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 50px;
}

main {
  width: 100%;
  margin: auto;
  padding-bottom: 40px;
  display: block;
}

@media screen and (min-width: 768px) {
  main {
    padding-bottom: 120px;
  }
}

.contents-inner {
  padding: 0 15px;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: -webkit-box !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: -webkit-inline-box !important;
  display: inline-flex !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 980px) {
  .contents-inner {
    padding: 0 40px;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -ms-flex-direction: row !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
          flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  -webkit-box-flex: 1 !important;
          flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  -webkit-box-flex: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  -webkit-box-flex: 1 !important;
          flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  -webkit-box-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  -webkit-box-pack: end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  -webkit-box-pack: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  -webkit-box-pack: justify !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  -webkit-box-align: start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  -webkit-box-align: end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  -webkit-box-align: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  -webkit-box-align: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  -ms-grid-row-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  -ms-grid-row-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
}

@media (min-width: 480px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 980px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 480px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 980px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 980px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

/* font document */
body {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background-color: #fff;
}

body.cria {
  background: linear-gradient(#00B9B2 0%, #369ABF 100%);
}

body.prekyu{
  background: linear-gradient(#EAA303 0%, #F15A22 100%);
}


.loading {
	text-align: center;
	width: 100%;
}
.title {
  font-size: 20px;
  color: #0D363C;
  font-weight: normal;
  padding: 26px 0;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 30px;
    font-weight: normal;
    padding: 60px 0;
  }
}

.sub-title {
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
}

@media screen and (min-width: 768px) {
  .sub-title {
    font-size: 18px;
  }
}

.nav {
  font-size: 16px;
}

.tx12 {
  font-size: 12px;
}

.tx14 {
  font-size: 14px;
}

.tx15 {
  font-size: 15px;
}

.tx16 {
  font-size: 16px;
}

.tx18 {
  font-size: 18px;
}

/* parts document */
.btn {
  border: none;
  font-size: 18px;
  border-radius: 32px;
  line-height: 64px;
  width: 300px;
  height: 64px;
  text-align: center;
  color: #0D363C;
  font-weight: bold;
  display: inline-block;
}

.btn:hover {
  opacity: 0.8;
  color: #0D363C;
}

@media screen and (min-width: 768px) {
  .btn {
    width: 330px;
  }
}

.btn-primary {
  background: #FACB4E;
}

.btn-dark {
  background: #E9E9E9;
}

.btn-disable {
  background: #D0D0D0;
  color: #fff;
}

.btn-disable:hover {
  background: #D0D0D0;
  color: #fff;
  opacity: 1;
}

.table-border th, .table-border td {
  padding: 0.65em 0;
  border-bottom: 1px solid #E9E9E9;
  text-align: left;
}

.table-border th {
  padding-right: 10px;
}

.price-value {
  margin-right: 3px;
}

.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  left: 0;
  top: 0;
}

.modal-bg {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999;
}

.modal-wrapper {
  position: absolute;
  top: calc(50% - 20px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  text-align: center;
  width: 740px;
  max-width: 90%;
  top: 0%;
  margin: 20px auto;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  z-index: 1000;
}

@media screen and (min-width: 768px) {
  .modal-wrapper {
    position: absolute;
    top: calc(50% - 20px);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.modal-wrapper .modal-title {
  background: #4AC2B8;
  color: #fff;
  padding: 20px 10px 15px 10px;
  border-radius: 10px 10px 0 0;
}

.modal-wrapper .modal-contents {
  padding: 30px 20px;
}

@media screen and (min-width: 768px) {
  .modal-wrapper .modal-contents {
    padding: 30px;
  }
}

.modal-wrapper table {
  margin: auto;
}

.modal-wrapper .link-help {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .modal-wrapper .link-help {
    padding-top: 0;
    padding-bottom: 0;
  }
}

body.modal-long .modal {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}

body.modal-long .modal .modal-wrapper {
  position: relative;
  left: 0;
  top: 0;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0);
}

body.modal-long main {
  position: fixed;
}

body.modal-long footer {
  position: relative;
}

.code-tab {
  position: relative;
  max-width: 260px;
  border: 5px solid #e9e9e9;
  margin: 20px auto 20px auto;
  padding: 0px 20px 10px 20px;
}

@media screen and (min-width: 768px) {
  .code-tab {
    width: 90%;
    max-width: 300px;
    margin-top: 30px;
  }
}

.code-tab h4 {
  font-size: 15px;
  font-weight: bold;
  background: #e9e9e9;
  padding: 5px 0;
  margin-top: -1px;
}

.code-tab .code-body {
  text-align: center;
}

.code-tab .code-body .code-detail {
  padding-top: 15px;
  display: inline-block;
  text-align: left;
}

.code-tab .code-body .code-detail td {
  border-bottom: 1px solid #E9E9E9;
  padding-top: 5px;
  padding-bottom: 5px;
}

.code-tab .code-body .code-detail tr:last-child td {
  border: none;
  padding-bottom: 0;
}

.code-tab .code-body .code-value {
  padding-left: 10px;
  font-size: 16px;
}

/* request page document */
#requestHome:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 150px;
  top: -50px;
  left: 0;
  background: #4AC2B8;
  z-index: -1;
}

#requestHome .title {
  color: #fff;
  padding: 10px 0 15px 0;
}

@media screen and (min-width: 768px) {
  #requestHome .title {
    padding-top: 0;
  }
}

#requestHome .headline {
  background: #4AC2B8;
}

#requestHome .max-price-box-bg {
  border-radius: 20px 20px 0 0;
  background: rgba(255, 255, 255, 0.13);
  padding: 15px 15px 25px 15px;
  width: 310px;
  max-width: 100%;
  margin: auto;
}

@media screen and (min-width: 768px) {
  #requestHome .max-price-box-bg {
    width: 350px;
    padding: 20px 20px 30px 20px;
  }
}

#requestHome .max-price-box {
  border-radius: 10px;
  border-bottom-width: 25px;
  background: #fff;
  padding: 30px 0px;
}

#requestHome .max-price-ttl h3 {
  font-weight: normal;
  font-size: 13px;
}

@media screen and (min-width: 768px) {
  #requestHome .max-price-ttl h3 {
    font-size: 16px;
  }
  #requestHome .max-price-ttl h3 .max-price-date {
    font-size: 14px;
  }
}

#requestHome .max-price-box .sbox {
  color: #6E868A;
  margin: 0 5px;
}

#requestHome .max-price-box .sbox.sbox-price {
  text-align: right;
  min-width: 120px;
  display: inline-block;
  white-space: nowrap;
}

#requestHome .max-price-box .sbox.sbox-price small {
  font-weight: bold;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  #requestHome .max-price-box .sbox.sbox-price small {
    font-size: 18px;
  }
}

@media screen and (max-width: 320px) {
  #requestHome .max-price-box .sbox.sbox-price {
    margin-left: 0;
    min-width: 105px;
  }
}

#requestHome .max-price-value {
  font-size: 28px;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  #requestHome .max-price-value {
    font-size: 30px;
  }
}

#requestHome section.request-box {
  padding-top: 20px;
}

#requestHome .request-receive-box {
  text-align: left;
}

#requestHome .request-price {
  background: #F5F5F5;
  border: 1px solid #E9E9E9;
  width: 286px;
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  margin: auto;
}

@media screen and (min-width: 768px) {
  #requestHome .request-price {
    width: 350px;
  }
}

#requestHome .request-price-entry {
  background: #fff;
  position: relative;
  width: 200px;
  margin: auto;
  padding-right: 10px;
  text-align: right;
  color: #0D363C;
}

@media screen and (min-width: 768px) {
  #requestHome .request-price-entry {
    width: 262px;
  }
}

#requestHome .request-price-entry .entry-btn {
  position: absolute;
  top: 0;
  right: -60px;
  width: 50px;
  cursor: pointer;
}

#requestHome .request-price-entry .entry-btn.btn-minus {
  left: -60px;
}

#requestHome .request-price-entry .entry-btn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 320px) {
  #requestHome .request-price-entry .entry-btn {
    right: -50px;
  }
  #requestHome .request-price-entry .entry-btn.btn-minus {
    left: -50px;
  }
}

#requestHome .request-price-entry .request-price-value .price-value {
  font-size: 30px;
  font-weight: bold;
}

#requestHome .request-price-entry .request-price-value small {
  font-size: 14px;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  #requestHome .request-price-entry .request-price-value .price-value {
    font-size: 37px;
  }
  #requestHome .request-price-entry .request-price-value small {
    font-size: 20px;
  }
}

#requestHome .request-price-detail {
  width: 180px;
  margin: 10px auto 0 auto;
}

#requestHome .request-price-detail table {
  width: 100%;
}

#requestHome .request-price-detail table th {
  text-align: left;
}

@media screen and (min-width: 768px) {
  #requestHome .request-price-detail {
    width: 200px;
  }
  #requestHome .request-price-detail table {
    font-size: 17px;
  }
}

#requestHome .request-price-detail .price-detail-value {
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  #requestHome .request-price-detail .price-detail-value {
    font-size: 22px;
  }
}

#requestHome .request-price-detail .price-fee-value {
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  #requestHome .request-price-detail .price-fee-value {
    font-size: 19px;
  }
}

#requestHome .request-receive {
  border-top: 1px solid #4AC2B8;
  border-bottom: 1px solid #4AC2B8;
  margin: 30px auto;
  padding-bottom: 30px;
}

#requestHome .request-receive-box {
  border: 3px solid #E9E9E9;
  border-radius: 10px;
  -ms-flex-preferred-size: 340px;
      flex-basis: 340px;
  max-width: 340px;
  padding: 10px;
  margin: 0 auto 10px auto;
  color: #6E868A;
}

#requestHome .request-receive-box .select-receive {
  background: url(../img/select-off.svg) no-repeat;
  height: 32px;
  line-height: 32px;
  padding-left: 40px;
  font-weight: bold;
  font-size: 16px;
}

#requestHome .request-receive-box .select-receive-inner {
  display: none;
  margin: 10px;
  padding-top: 10px;
  border-top: 2px dotted #6E868A;
}

#requestHome .request-receive-box .request-receive-detail {
  font-size: 16px;
  text-align: center;
  padding: 15px 0;
}

#requestHome .request-receive-box .request-receive-detail p, #requestHome .request-receive-box .request-receive-detail table {
  display: inline-block;
  text-align: left;
}

#requestHome .request-receive-box .request-receive-detail p th, #requestHome .request-receive-box .request-receive-detail table th {
  font-weight: normal;
  padding-right: 10px;
}

@media screen and (min-width: 768px) {
  #requestHome .request-receive-box .request-receive-detail_right {
    padding: 30px 0;
  }
}

#requestHome .request-receive-box:hover {
  border-color: #4AC2B8;
  cursor: pointer;
}

#requestHome .request-receive-box.select-active {
  border-color: #4AC2B8;
  background: #effefd;
}

#requestHome .request-receive-box.select-active .select-receive-inner {
  display: block;
}

#requestHome .request-receive-box.select-active .select-receive {
  background: url(../img/select-on.svg) no-repeat;
  color: #3d5e63;
}

#requestHome .request-receive-box .warning {
  font-size: 13px;
}

#requestHome.bank-only .request-receive-box.only-bank {
  background: none;
}

#requestHome.bank-only .request-receive-box.only-bank .select-receive {
  background: none;
  padding-left: 0;
  text-align: center;
}

#requestHome.bank-only .select-receive-inner {
  display: block;
}

/* request-modal */
.modal-wrapper.request-home .modal-title h3 {
  font-weight: normal;
  line-height: 1;
}

.modal-wrapper.request-home .modal-title h3 .icon {
  display: inline-block;
  vertical-align: middle;
}

.modal-wrapper.request-home .modal-title h3 .icon img {
  width: 36px;
  margin: 0 5px 0px 0;
  line-height: 0;
}

@media screen and (min-width: 768px) {
  .modal-wrapper.request-home .modal-title h3 {
    padding: 3px;
    line-height: 1.8;
  }
  .modal-wrapper.request-home .modal-title h3 .icon {
    display: block;
  }
  .modal-wrapper.request-home .modal-title h3 .icon img {
    width: 42px;
  }
}

.modal-wrapper.request-home .confirm-pay {
  background: #F5F5F5;
  width: 230px;
  margin: auto;
  padding: 12px 15px;
}

.modal-wrapper.request-home .confirm-pay .pay-title {
  position: relative;
  text-align: left;
}

.modal-wrapper.request-home .confirm-pay .pay-title > span {
  font-weight: bold;
  background: #fff;
  display: inline-block;
  padding: 5px 8px;
  margin-left: 0;
  z-index: 2;
  position: relative;
}

.modal-wrapper.request-home .confirm-pay .pay-title:before {
  content: "";
  width: 100%;
  border-top: 2px solid #fff;
  position: absolute;
  top: 50%;
  left: 0;
}

.modal-wrapper.request-home .confirm-pay .pay-price {
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  margin: -3px 0;
}

.modal-wrapper.request-home .confirm-pay .pay-value {
  font-size: 32px;
  display: inline-block;
  font-weight: bold;
}

.modal-wrapper.request-home .pay-detail {
  margin: 20px 0 20px 0;
}

.modal-wrapper.request-home .pay-detail table.pay-table {
  min-width: 260px;
}

.modal-wrapper.request-home .pay-detail table.pay-table th {
  font-weight: bold;
}

.modal-wrapper.request-home .i-sevenatm {
  width: 70px;
}

.modal-wrapper.request-home .modal-btn-action .btn {
  cursor: pointer;
  width: 260px;
  max-width: 100%;
  margin: 5px 0;
}

@media screen and (min-width: 768px) {
  .modal-wrapper.request-home .modal-btn-action .btn {
    width: 295px;
    margin: 0 10px;
  }
}

@media screen and (min-width: 768px) {
  .modal-wrapper.request-home .modal-btn-action {
    padding: 10px 0px;
  }
}

.modal-wrapper.request-home.complete .pay-detail .pay-table td {
  font-size: 16px;
}

.modal-wrapper.request-home.complete .pay-price {
  font-weight: bold;
}

.modal-wrapper.request-home.complete .pay-value {
  font-size: 25px;
  font-weight: bold;
}

.modal-wrapper.request-home.complete.complete-seven .pay-detail {
  margin-top: 10px;
}

.modal-wrapper.request-home.complete.complete-seven .link-help {
  margin-bottom: 10px;
}

.btn {
  max-width: 100%;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  #requestHome .request-receive {
    border: none;
  }
  #requestHome .request-receive-box {
    padding: 20px 25px;
    margin: 0 10px 10px 10px;
    border-width: 9px;
    -ms-flex-preferred-size: 360px;
        flex-basis: 360px;
    max-width: 360px;
  }
  #requestHome .request-receive-box .select-receive-inner {
    display: block;
    margin: 10px 0 0 0;
    padding-top: 15px;
  }
  #requestHome .request-receive-box:hover {
    border-color: #96dcd6;
  }
  #requestHome .request-receive-box.select-active {
    border-color: #96dcd6;
    background: #fff;
  }
  #requestHome .request-receive-box.select-active .select-receive {
    color: #4AC2B8;
  }
}

#header, #multiple {
  position: fixed;
  width: 100%;
  z-index: 995;
  top: 0;
}

#header .header-inner, #multiple .header-inner {
  position: relative;
  background: #4AC2B8;
  color: white;
  padding: 10px 15px;
}

@media screen and (min-width: 980px) {
  #header .header-inner, #multiple .header-inner {
    padding: 10px 40px;
  }
}

#header .header-inner h1 img, #multiple .header-inner h1 img {
  display: block;
  margin: auto;
}

@media screen and (min-width: 480px) {
  #header .header-inner h1 img, #multiple .header-inner h1 img {
    margin-left: 0;
  }
}

#header .header-inner .company_name, #multiple .header-inner .company_name {
  position: relative;
  display: inline-block;
  width: auto;
  height: 34px;
  padding: 0 20px;
  margin-right: 30px;
}

#header .header-inner .company_name .select_btn, #multiple .header-inner .company_name .select_btn {
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0 14px;
  padding-right: 28px;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  width: 100%;
  max-width: 226px;
  height: 34px;
  line-height: 34px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

@media screen and (min-width: 480px) {
  #header .header-inner .company_name .select_btn, #multiple .header-inner .company_name .select_btn {
    max-width: 268px;
    font-size: 16px;
  }
}

#header .header-inner .company_name .select_btn::after, #multiple .header-inner .company_name .select_btn::after {
  content: '';
  width: 9px;
  height: 6px;
  border-top: 6px solid #fff;
  border-right: 4.5px solid transparent;
  border-left: 4.5px solid transparent;
  margin: auto 0;
  position: absolute;
  top: 0;
  left: auto;
  right: 10px;
  bottom: 0;
}

#header .header-inner .company_name ul.select_box_menu, #multiple .header-inner .company_name ul.select_box_menu {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 276px;
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  bottom: auto;
  color: rgba(13, 54, 60, 0.6);
  border: 1px solid #4AC2B8;
  background-color: #fff;
  padding: 5px 16px;
  margin: 0 auto;
  list-style: none;
}

#header .header-inner .company_name ul.select_box_menu li, #multiple .header-inner .company_name ul.select_box_menu li {
  border-bottom: dashed 1px #0D363C;
  padding: 5px 0;
}

#header .header-inner .company_name ul.select_box_menu li div, #multiple .header-inner .company_name ul.select_box_menu li div {
  cursor: pointer;
  padding: 0.5rem;
}

#header .header-inner .company_name ul.select_box_menu li div p, #multiple .header-inner .company_name ul.select_box_menu li div p {
  font-weight: bold;
}

#header .header-inner .company_name ul.select_box_menu li div p small, #multiple .header-inner .company_name ul.select_box_menu li div p small {
  font-size: 12px;
  font-weight: normal;
}

#header .header-inner .company_name ul.select_box_menu li div p span, #multiple .header-inner .company_name ul.select_box_menu li div p span {
  margin-left: 0.5rem;
  display: inline-block;
  min-width: 65px;
  text-align: right;
}

#header .header-inner .company_name ul.select_box_menu li div:hover, #multiple .header-inner .company_name ul.select_box_menu li div:hover {
  background-color: rgba(74, 194, 184, 0.2);
}

#header .header-inner .company_name ul.select_box_menu li:last-child, #multiple .header-inner .company_name ul.select_box_menu li:last-child {
  border-bottom: none;
}

#header .header-inner nav, #multiple .header-inner nav {
  height: 16px;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto 0;
}

@media screen and (min-width: 980px) {
  #header .header-inner nav, #multiple .header-inner nav {
    right: 40px;
  }
}

#header .header-inner nav .nav_inner, #multiple .header-inner nav .nav_inner {
  position: relative;
}

#header .header-inner nav .nav_inner #nav-input, #multiple .header-inner nav .nav_inner #nav-input {
  display: none;
}

#header .header-inner nav .nav_inner #nav-input:checked ~ #nav-close, #multiple .header-inner nav .nav_inner #nav-input:checked ~ #nav-close {
  display: block;
  opacity: .5;
}

#header .header-inner nav .nav_inner #nav-input:checked ~ #nav-menu-list, #multiple .header-inner nav .nav_inner #nav-input:checked ~ #nav-menu-list {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
          box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
}

#header .header-inner nav .nav_inner #nav-input:checked ~ #nav-open span:nth-of-type(1), #multiple .header-inner nav .nav_inner #nav-input:checked ~ #nav-open span:nth-of-type(1) {
  -webkit-transform: translateY(7px) rotate(-45deg);
  transform: translateY(7px) rotate(-45deg);
}

#header .header-inner nav .nav_inner #nav-input:checked ~ #nav-open span:nth-of-type(2), #multiple .header-inner nav .nav_inner #nav-input:checked ~ #nav-open span:nth-of-type(2) {
  left: 50%;
  opacity: 0;
}

#header .header-inner nav .nav_inner #nav-input:checked ~ #nav-open span:nth-of-type(3), #multiple .header-inner nav .nav_inner #nav-input:checked ~ #nav-open span:nth-of-type(3) {
  -webkit-transform: translateY(-7px) rotate(45deg);
  transform: translateY(-7px) rotate(45deg);
}

#header .header-inner nav .nav_inner #nav-open, #multiple .header-inner nav .nav_inner #nav-open {
  display: inline-block;
  -webkit-transition: all .4s;
  transition: all .4s;
  position: relative;
  width: 30px;
  height: 16px;
  cursor: pointer;
  z-index: 998;
}

#header .header-inner nav .nav_inner #nav-open span, #multiple .header-inner nav .nav_inner #nav-open span {
  -webkit-transition: 0.4s cubic-bezier(0.3, 0.6, 0.6, 1);
  transition: 0.4s cubic-bezier(0.3, 0.6, 0.6, 1);
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 3px;
  background: white;
}

#header .header-inner nav .nav_inner #nav-open span:nth-of-type(1), #multiple .header-inner nav .nav_inner #nav-open span:nth-of-type(1) {
  top: 0;
}

#header .header-inner nav .nav_inner #nav-open span:nth-of-type(2), #multiple .header-inner nav .nav_inner #nav-open span:nth-of-type(2) {
  top: 7px;
}

#header .header-inner nav .nav_inner #nav-open span:nth-of-type(3), #multiple .header-inner nav .nav_inner #nav-open span:nth-of-type(3) {
  bottom: 0px;
}

#header .header-inner nav .nav_inner #nav-close, #multiple .header-inner nav .nav_inner #nav-close {
  display: none;
  position: fixed;
  z-index: 996;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

#header .header-inner nav .nav_inner ul#nav-menu-list, #multiple .header-inner nav .nav_inner ul#nav-menu-list {
  color: white;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 997;
  width: 75%;
  max-width: 250px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: 0.5s cubic-bezier(0.3, 0.6, 0.6, 1);
  transition: 0.5s cubic-bezier(0.3, 0.6, 0.6, 1);
  -webkit-transform: translateX(105%);
  transform: translateX(105%);
  padding: 3rem 2rem;
}

@media screen and (min-width: 768px) {
  #header .header-inner nav .nav_inner ul#nav-menu-list, #multiple .header-inner nav .nav_inner ul#nav-menu-list {
    max-width: 270px;
  }
}

#header .header-inner nav .nav_inner ul#nav-menu-list li, #multiple .header-inner nav .nav_inner ul#nav-menu-list li {
  padding: 0.8rem 0;
  line-height: 30px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}

@media screen and (min-width: 768px) {
  #header .header-inner nav .nav_inner ul#nav-menu-list li, #multiple .header-inner nav .nav_inner ul#nav-menu-list li {
    padding: 1rem 0;
  }
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a {
  padding-left: calc( 8px + 29px);
  color: #F5F5F5;
  width: 100%;
  line-height: 1.6em;
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a:hover, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a:hover {
  opacity: 0.8;
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a:before, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a:before {
  width: 29px;
  height: 29px;
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a.icon-request:before, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a.icon-request:before {
  background: url(../img/menu-icon-request.svg) no-repeat;
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a.icon-history:before, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a.icon-history:before {
  background: url(../img/menu-icon-history.svg) no-repeat;
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a.icon-news:before, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a.icon-news:before {
  background: url(../img/menu-icon-news.svg) no-repeat;
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a.icon-seven:before, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a.icon-seven:before {
  background: url(../img/menu-icon-seven.svg) no-repeat;
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a.icon-user:before, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a.icon-user:before {
  background: url(../img/menu-icon-user.svg) no-repeat;
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a.icon-kiyaku:before, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a.icon-kiyaku:before {
  background: url(../img/menu-icon-kiyaku.svg) no-repeat;
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a.icon-policy:before, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a.icon-policy:before {
  background: url(../img/menu-icon-policy.svg) no-repeat;
}

#header .header-inner nav .nav_inner ul#nav-menu-list li a.icon-logout:before, #multiple .header-inner nav .nav_inner ul#nav-menu-list li a.icon-logout:before {
  background: url(../img/menu-icon-logout.svg) no-repeat;
}

main {
  padding-top: 65.13px;
}

footer {
  font-size: small;
  color: #0D363C;
  line-height: 45px;
  height: 45px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  footer {
    background-color: #F5F5F5;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
  }
}

/* login-app-pass page document */
.modal.login-app-pass .modal-wrapper {
  width: 456px;
  padding: 28px 15px 45px 15px;
}

@media screen and (min-width: 480px) {
  .modal.login-app-pass .modal-wrapper {
    padding: 45px;
  }
}

.modal.login-app-pass .modal-wrapper .modal-title {
  background-color: #fff;
  color: #4AC2B8;
  font-size: 20px;
  padding: 0;
}

@media screen and (min-width: 480px) {
  .modal.login-app-pass .modal-wrapper .modal-title {
    font-size: 22px;
  }
}

.modal.login-app-pass .modal-wrapper .modal-title i {
  display: block;
  padding-bottom: 18px;
}

.modal.login-app-pass .modal-wrapper .modal-title span {
  font-weight: bold;
}

.modal.login-app-pass .modal-wrapper .modal-contents {
  padding: 0;
  padding-top: 25px;
}

.modal.login-app-pass .modal-wrapper .modal-contents .modal-inner-contents {
  margin: auto;
  display: inline-block;
}

.modal.login-app-pass .modal-wrapper .modal-contents .modal-inner-contents > p {
  display: inline-block;
  padding-bottom: 2rem;
}

.modal.login-app-pass .modal-wrapper .modal-contents .modal-inner-contents form label {
  padding-bottom: 2.5rem;
  width: 245px;
}

.modal.login-app-pass .modal-wrapper .modal-contents .modal-inner-contents form label input {
  width: 42px;
  height: 50px;
  font-size: 3rem;
  max-width: 54px;
  max-height: 63px;
  margin: 1vw;
  border: solid 1px #D0D0D0;
  font-family: inherit;
  display: inline-block;
}

@media screen and (min-width: 480px) {
  .modal.login-app-pass .modal-wrapper .modal-contents .modal-inner-contents form label input {
    margin: 2.5px;
  }
}

.modal.login-app-pass .modal-wrapper .modal-contents .modal-inner-contents form P {
  padding-top: 1rem;
  text-align: center;
  display: block;
  color: #D67373;
}

.modal.login-app-pass .modal-wrapper .modal-contents .modal-inner-contents form button {
  margin-top: 35px;
  border: 0;
  max-width: 260px;
}

@media screen and (min-width: 480px) {
  .modal.login-app-pass .modal-wrapper .modal-contents .modal-inner-contents form button {
    max-width: 335px;
  }
}

/* history page document */
#requestHistory main {
  padding-bottom: 10px;
}

@media screen and (min-width: 768px) {
  #requestHistory main {
    padding-bottom: 70px;
  }
}

#requestHistory .contents-inner {
  border-bottom: 1px solid #E9E9E9;
  max-width: 600px;
  margin: auto;
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner {
    max-width: 980px;
    border-bottom: none;
  }
}

#requestHistory .contents-inner > article {
  padding-bottom: 20px;
}

#requestHistory .contents-inner .none-br {
  display: none;
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .none-br {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .box {
    padding: 1rem 2rem .6rem 2rem;
    border: 4px solid #bce9e5;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
    margin-bottom: .5rem;
  }
}

#requestHistory .contents-inner .box .box-title {
  background: #87d7d0;
  color: white;
  padding: .2rem 1rem 1rem 1rem;
  margin-bottom: .2rem;
  border-radius: 10px;
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .box .box-title {
    width: 35%;
    background: #eaf8f7;
    padding: 2rem;
    margin: -2rem;
  }
}

#requestHistory .contents-inner .box .box-title .box-title-day {
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .box .box-title .box-title-day {
    color: #4AC2B8;
    font-weight: bolder;
    text-align: center;
    border-bottom: 1px solid #4AC2B8;
    margin-bottom: .8rem;
  }
}

#requestHistory .contents-inner .box .box-title .box-title-day span {
  font-size: 35px;
}

#requestHistory .contents-inner .box .box-title .box-title-price {
  font-size: 15px;
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .box .box-title .box-title-price {
    color: #0D363C;
    padding-bottom: 1rem;
  }
}

#requestHistory .contents-inner .box .box-title .box-title-price .price {
  font-size: 22px;
  padding-left: 5px;
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .box .box-title .box-title-price .price {
    padding-left: 0px;
  }
}

#requestHistory .contents-inner .box .box-title .box-title-price .deduction {
  font-size: 12px;
  font-weight: normal;
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .box .box-contents {
    width: 65%;
  }
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item {
  border-top: 1px solid #D0D0D0;
  padding: 1rem .5rem 0 .5rem;
  position: relative;
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item:first-child {
  border: none;
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item {
    padding: 1rem .5rem 0 1rem;
  }
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item h4 {
  padding-bottom: .6rem;
  white-space: nowrap;
}

@media screen and (min-width: 980px) {
  #requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item h4 {
    width: 35%;
    margin-top: 1px;
  }
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table {
  width: 100%;
  max-width: 280px;
  margin: 0 auto .5rem auto;
}

@media screen and (min-width: 480px) {
  #requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table {
    width: 68%;
    margin: 0 0 1rem 0.5rem;
    max-width: 500px;
  }
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table {
    width: 100%;
    margin: 0 0 1rem 0;
  }
}

@media screen and (min-width: 980px) {
  #requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table {
    width: 70%;
    margin: 0 0 1rem 1rem;
  }
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr th {
  padding-bottom: .5rem;
  text-align: left;
  white-space: nowrap;
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr td {
  width: auto;
  text-align: right;
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr td {
    padding-right: 130px;
  }
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr.border-top {
  border-top: 1px dotted #D0D0D0;
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr.border-top th, #requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr.border-top td {
  padding-top: .5rem;
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr:nth-child(-n+2) {
  font-size: 16px;
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr:nth-child(-n+2) td {
  font-size: 18px;
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr:nth-last-child(-n+3) {
  color: #6E868A;
}

#requestHistory .contents-inner .box .box-contents .box-contents-inner .box-contents-item table tr:last-child th {
  padding: 0rem;
}

#requestHistory .contents-inner .box button {
  cursor: pointer;
  font-size: 14px;
  border: 0px solid;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  text-align: center;
  height: 45px;
  border-radius: 6px;
  background: #E9E9E9;
  color: #3d5e63;
  margin-bottom: 1rem;
  display: block;
}

@media screen and (min-width: 480px) {
  #requestHistory .contents-inner .box button {
    position: absolute;
    padding: 0 1.5rem;
    margin-bottom: 0;
    right: 0px;
    left: auto;
    top: 55px;
    width: auto;
  }
}

#requestHistory .contents-inner .box button:hover {
  background: #D0D0D0;
}

@media screen and (min-width: 768px) {
  #requestHistory .contents-inner .box button {
    font-size: 14px;
    right: 30px;
    top: 63px;
    padding: 0 1.8rem;
    height: 35px;
  }
}

@media screen and (min-width: 980px) {
  #requestHistory .contents-inner .box button {
    font-size: 14px;
    right: 10px;
    top: 27px;
    padding: 0 1.8rem;
    height: 35px;
  }
}

#requestHistory .modal-wrapper.request-history {
  width: 550px;
  max-width: 90%;
  border-radius: 10px;
}

#requestHistory .modal-wrapper.request-history h3 {
  font-size: 18px;
  color: #0D363C;
  margin-bottom: 15px;
}

#requestHistory .modal-wrapper.request-history h3 .num {
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  #requestHistory .modal-wrapper.request-history h3 {
    padding: 20px 10px 10px 10px;
  }
  #requestHistory .modal-wrapper.request-history h3 .num {
    font-size: 22px;
  }
}

#requestHistory .modal-wrapper.request-history .pay-detail {
  margin: auto;
  margin-bottom: 20px;
}

#requestHistory .modal-wrapper.request-history .pay-detail .pay-table {
  width: 257px;
}

#requestHistory .modal-wrapper.request-history .pay-detail .pay-table th {
  font-weight: bold;
}

#requestHistory .modal-wrapper.request-history .pay-detail .pay-table th, #requestHistory .modal-wrapper.request-history .pay-detail .pay-table td {
  border: none;
  padding: 0.25rem;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  #requestHistory .modal-wrapper.request-history .pay-detail .pay-table {
    width: 336px;
  }
  #requestHistory .modal-wrapper.request-history .pay-detail .pay-table th, #requestHistory .modal-wrapper.request-history .pay-detail .pay-table td {
    font-size: 16px;
    padding: 0.25rem;
  }
}

@media screen and (max-width: 320px) {
  #requestHistory .modal-wrapper.request-history .pay-detail .pay-table {
    width: 230px;
  }
}

#requestHistory .modal-wrapper.request-history .pay-detail .bdr-line th, #requestHistory .modal-wrapper.request-history .pay-detail .bdr-line td {
  padding-top: 2em;
}

#requestHistory .modal-wrapper.request-history .pay-detail .bdr-line th.bdr-top-line {
  position: relative;
}

#requestHistory .modal-wrapper.request-history .pay-detail .bdr-line th.bdr-top-line:after {
  content: "";
  width: 257px;
  position: absolute;
  height: 1px;
  border-top: 2px dotted #6E868A;
  left: 0;
  top: 0.75em;
}

@media screen and (min-width: 768px) {
  #requestHistory .modal-wrapper.request-history .pay-detail .bdr-line th.bdr-top-line:after {
    width: 336px;
  }
}

@media screen and (max-width: 320px) {
  #requestHistory .modal-wrapper.request-history .pay-detail .bdr-line th.bdr-top-line:after {
    width: 230px;
  }
}

#requestHistory .modal-wrapper.request-history.type-seven .pay-detail .pay-table {
  width: 280px;
}

@media screen and (min-width: 768px) {
  #requestHistory .modal-wrapper.request-history.type-seven .pay-detail .pay-table {
    width: 336px;
  }
}

@media screen and (max-width: 320px) {
  #requestHistory .modal-wrapper.request-history.type-seven .pay-detail .pay-table {
    width: 230px;
  }
}

#requestHistory .modal-wrapper.request-history.type-seven .pay-detail .bdr-line th.bdr-top-line:after {
  width: 280px;
}

@media screen and (min-width: 768px) {
  #requestHistory .modal-wrapper.request-history.type-seven .pay-detail .bdr-line th.bdr-top-line:after {
    width: 336px;
  }
}

@media screen and (max-width: 320px) {
  #requestHistory .modal-wrapper.request-history.type-seven .pay-detail .bdr-line th.bdr-top-line:after {
    width: 230px;
  }
}

#requestHistory .modal-wrapper.request-history.type-seven .code-tab {
  max-width: 280px;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  #requestHistory .modal-wrapper.request-history.type-seven .code-tab {
    width: 90%;
    max-width: 336px;
  }
}

#requestHistory .modal-wrapper.request-history .link-help {
  margin-bottom: 15px;
  margin-top: -5px;
}

@media screen and (min-width: 768px) {
  #requestHistory .modal-wrapper.request-history .link-help {
    margin: 0px auto 25px auto;
  }
}

#requestHistory .modal-wrapper.request-history .history-bank-box {
  border: 5px solid #e9e9e9;
  padding: 10px 15px;
  margin: 20px auto 30px auto;
  width: 90%;
  max-width: 257px;
}

@media screen and (min-width: 768px) {
  #requestHistory .modal-wrapper.request-history .history-bank-box {
    font-size: 16px;
    max-width: 336px;
  }
}

#requestHistory .modal-wrapper.request-history .history-bank-box table {
  text-align: left;
}

#requestHistory .modal-wrapper.request-history .history-bank-box table tr:first-child th,
#requestHistory .modal-wrapper.request-history .history-bank-box table tr:first-child td {
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 10px;
}

#requestHistory .modal-wrapper.request-history .history-bank-box table th {
  font-weight: bold;
  padding: 10px 20px 10px 0px;
}

#requestHistory .modal-wrapper.request-history .modal-btn-action {
  padding-bottom: 1em;
}

/* セブン受取方法 page document */
#howtoSeven .contents-inner {
  padding: 1rem 1.5rem;
}

#howtoSeven .contents-inner .none-br {
  display: none;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .none-br {
    display: block;
  }
}

#howtoSeven .contents-inner .first-box {
  border-bottom: 1px dotted #3d5e63;
  padding-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .first-box {
    margin-left: auto;
    margin-right: auto;
    padding: 3rem 1rem;
    border-top: 1px solid #E9E9E9;
    border-bottom: none;
  }
}

#howtoSeven .contents-inner .first-box .first-inner-box {
  vertical-align: middle;
  margin: auto;
  padding: 0 1rem 1rem 1rem;
}

#howtoSeven .contents-inner .first-box .first-inner-box img.img-seven-atm {
  margin: 0 -10px 0 -20px;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .first-box .first-inner-box {
    margin: 0;
    padding: 0 1rem;
  }
  #howtoSeven .contents-inner .first-box .first-inner-box img.img-seven-atm {
    width: 105px;
    height: 105px;
    margin: 0 -20px 0 -30px;
  }
}

#howtoSeven .contents-inner .first-box .first-inner-box .img-sub {
  margin: auto 0;
  line-height: 1.6em;
  font-size: 16px;
  height: 3em;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .first-box .first-inner-box .img-sub {
    font-size: 18px;
  }
}

#howtoSeven .contents-inner .first-box .description-box {
  background: #e3f5f4;
  padding: 1rem 1.2rem;
  font-weight: normal;
  line-height: 23px;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .first-box .description-box {
    width: 65%;
    max-width: 530px;
    padding: 1rem 1rem 1rem 1.2rem;
  }
}

#howtoSeven .contents-inner .box {
  margin: auto;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box.flow-box {
    max-width: 680px;
  }
}

@media screen and (min-width: 980px) {
  #howtoSeven .contents-inner .box.flow-box {
    max-width: 100%;
  }
}

#howtoSeven .contents-inner .box .box-contents-wrap {
  border-bottom: 1px dotted #3d5e63;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box .box-contents-wrap {
    width: 45%;
    border-bottom: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 2rem 0;
  }
}

@media screen and (min-width: 980px) {
  #howtoSeven .contents-inner .box .box-contents-wrap {
    width: 31%;
  }
}

#howtoSeven .contents-inner .box .box-contents-wrap .box-contents {
  padding: 0 0 1.5rem 0;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents {
    padding: 0 0 .5rem 0;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
  }
}

#howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-title {
  padding-top: 1rem;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-title {
    padding-top: 0;
  }
}

#howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-title .box-title-number {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  background: #3d5e63;
  color: white;
  font-weight: bold;
  margin: 0 .5rem 0 0;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-title .box-title-number {
    height: 45px;
    width: 45px;
    border-radius: 10px 0px;
    line-height: 45px;
    text-align: center;
    margin: -1px .7rem 0 -1px;
    font-size: 24px;
  }
}

#howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-title .box-title-text {
  font-weight: bold;
  font-size: 16px;
  margin-top: auto;
  margin-bottom: auto;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-title .box-title-text {
    padding-top: .3rem;
  }
}

#howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-contents-item-wrap {
  position: relative;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-contents-item-wrap {
    padding: 1rem;
  }
}

#howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-contents-item-wrap .box-contents-item {
  padding: 0 0 1.5rem 2.7rem;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-contents-item-wrap .box-contents-item {
    padding: 235px 0 0rem 0;
    width: 290px;
  }
}

@media screen and (min-width: 980px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-contents-item-wrap .box-contents-item {
    padding-top: 200px;
    width: 240px;
  }
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-contents-item-wrap .flow-img {
    position: absolute;
    top: 20px;
    left: 17px;
  }
}

#howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-contents-item-wrap img {
  width: 305px;
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-contents-item-wrap img {
    width: 290px;
  }
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-contents-item-wrap img.flow-img-start {
    margin-top: 70px;
  }
}

@media screen and (min-width: 980px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .box-contents .box-contents-item-wrap img {
    width: 238px;
  }
}

@media screen and (min-width: 768px) {
  #howtoSeven .contents-inner .box .box-contents-wrap .triangle {
    width: 0;
    height: 0;
    border-left: 14px solid #6E868A;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    margin-top: auto;
    margin-bottom: auto;
  }
}

/* user page document */
#user .user-box {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

#user .contents-inner form input {
	display: block;
	width: 100%;
	padding: 0.5rem 0;
	border: none;
	border-bottom: solid 1px #c7c7c7;
	font-size: 20px;
	margin-bottom: 0;
}


@media screen and (min-width: 768px) {
  #user .user-box {
    border: 4px solid rgba(13, 54, 60, 0.3);
    border-radius: 10px;
  }
}

@media screen and (min-width: 768px) {
  #user .user-box h3.user-name {
    background-color: #F5F5F5;
    border-radius: 10px 10px 0px 0px;
  }
}

#user .user-box h3.user-name span {
  display: inline-block;
  position: relative;
  padding: 18px 0;
  padding-left: 33px;
  font-size: 18px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  #user .user-box h3.user-name span {
    padding: 24px 0;
    padding-left: 33px;
    font-size: 20px;
  }
}

#user .user-box h3.user-name span:before {
  content: "";
  background: url(../img/user.png) no-repeat;
  width: 23px;
  height: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  margin: auto 0;
  position: absolute;
}

#user .user-box h3.user-name hr {
  width: 165px;
  border-top: 1px solid #E9E9E9;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  #user .user-box h3.user-name hr {
    display: none;
  }
}

#user .user-box div.user-contents {
  margin: 0 auto;
  padding: 20px 5px 0px 5px;
}

/*
 * 口座情報変更
 */
#user .user-box .change-bank-account-box {
	text-align: center;
	padding: 16px;
}

#user .user-box div.user-contents {
	margin: 0 auto;
	padding: 20px 5px 0px 5px;
}
#user .user-box div.user-contents .user_bank-info-description {
	max-width: 416px;
	margin: auto;
}

#user .user-box div.user-contents .error {
	text-align: center;
	color: #cc0000;
	font-size: 13px;
	padding-top: 1rem;
}

#user .user-box select {
	cursor: pointer;
	outline: none;
	/*-webkit-appearance: none;*/
	/*	-moz-appearance: none;*/
	/*appearance: none;*/
	padding: 0 14px;
	padding-right: 28px;
	/*text-align: left;*/
	border: 1px solid rgba(149, 149, 149, 0.5);
	border-radius: 5px;
	background-color: transparent;
	font-size: 14px;
	/*font-weight: bold;*/
	width: 100%;
	max-width: 226px;
	height: 34px;
	line-height: 34px;
	position: relative;
	overflow: hidden;
	z-index: 1;
}

#user .user-box div.user-contents table.table-border {
  margin: 0 auto;
  word-break: break-all;
  min-width: 280px;
  font-size: 14px;
}

#user .user-box div.user-contents table.table-border tr th {
  white-space: nowrap;
  font-weight: bold;
  padding-right: 10px;
}

@media screen and (min-width: 480px) {
  #user .user-box div.user-contents table.table-border tr th {
    padding-right: 30px;
  }
}

#user .user-box div.user-contents table.table-border td img {
  vertical-align: middle;
  margin-right: 3px;
}

@media screen and (min-width: 480px) {
  #user .user-box div.user-contents table.table-border {
    min-width: 320px;
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  #user .user-box div.user-contents table.table-border {
    min-width: 416px;
  }
}

@media screen and (min-width: 768px) {
  #user .user-box div.user-contents {
    padding: 40px 5px;
  }
}

/* news page document */
#news .news-list .news-content {
  border-top: 1px dotted #e5e5e5;
  padding: 0.5rem 0;
}

@media screen and (min-width: 980px) {
  #news .news-list .news-content {
    border-style: solid;
  }
}

#news .news-list .news-content:last-child {
  border-bottom: 1px solid #e5e5e5;
}

@media screen and (min-width: 980px) {
  #news .news-list .news-content:last-child {
    border-style: solid;
  }
}

#news .news-list .news-content * {
  padding: 0.5rem 0;
}

#news .news-list .news-content h3 {
  line-height: 2rem;
}

#news .news-list .news-content h3 span {
  font-size: 18px;
  font-weight: bold;
}
/*# sourceMappingURL=common.css.map */
