main#settings {
  padding: 0;
  margin: 0 auto;
  width: fit-content;

  >section {
    display: flex;
    >section:first-child {
      max-width: 620px;
      width: 70vw;
      @media screen and (max-width: 680px) {
        width: 90vw; }
      border-radius: 8px;
      background: #F5F5F5;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
      text-align : left;

      border-radius: 8px;
      background: #F5F5F5;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);

      section {
        padding: 30px;
        display: flex;
        position: relative;
        >div:first-child {
          width: 75px;
          h2 {
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            text-align: left;
            color: #2F374E; } }
        >div:last-child {
          width: calc(100% - 75px);
          h2 {
            width: 100%;
            display: flex;
            margin-bottom: 20px;
            img {
              height: 24px;
              display: block;
              margin-right: 20px; }
            p {
              font-weight: bold;
              font-size: 18px;
              line-height: 30px;
              text-align: left;
              color: #2F374E;
              margin-top: -3px; } }
          dl {
            dt {
              font-weight: bold;
              font-size: 14px;
              line-height: 22px;
              text-align: left;
              color: #2F374E;
              margin-bottom: 5px; }
            dd {
              padding-left: 10px;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              text-align: left;
              color: #2F374E;
              margin-bottom: 10px;
              ul {
                list-style: none;
                li {
                  display: flex;
                  justify-content: left;
                  p:first-child {
                    width: 70px;
                    margin-right: 16px;
                  }
                  p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    text-align: left;
                    color: #2F374E; } } } } }
          .whiteout-button {
            position: absolute;
            right: 30px;
            bottom: 30px;
            button {
              width: 158px;
              height: 44px;
              border-radius: 22px;
              background: transparent;
              border: 2px solid #2F374E;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              text-align: center;
              color: #2F374E; } } } }

      div.languages{
        display: none;
        visibility: hidden;
      }
      .languages,div.terms,div.privacy-policy {
        display: flex;
        position: relative;
        background: #fff;
        height: 50px;
        border-top: 1px solid #D3D3D3;
        padding: 10px;
        p.current-language {
          cursor: pointer; }
        h3 {
          display: flex;
          margin-left: 20px;
          p {
            font-weight: bold;
            font-size: 14px;
            line-height: 30px;
            text-align: left;
            color: #2F374E; }
          img {
            width: 14px;
            height: 16px;
            margin-left: 4px;
            margin-top: calc( (30px - 16px) / 2 ); } }

        >p {
          position: absolute;
          right: 160px;
          top: 10px;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          text-align: left;
          color: #2F374E;
          opacity: 0.5; }
        >img {
          position: absolute;
          width: 40px;
          height: 30px;
          right: 10px;
          top: 10px; } }
      div.logout {
        height: 130px;
        button {
          max-width: 285px;
          width: 90%;
          height: 60px;
          margin-left: calc( (100% - 285px ) / 2);
          margin-top: calc( (130px - 60px ) / 2);
          border-radius: 30px;
          background: #fff;
          border: 2px solid #fff;

          font-weight: 500;
          font-size: 18px;
          letter-spacing: -0.02em;
          line-height: 28px;
          text-align: center;
          color: #2F374E;
          cursor: pointer; } } } }

  footer {
    margin-top : 50px;
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    color: #fff;

    background-color: unset;
    position: unset; } }

@media screen and (max-width: 680px) {
  main#settings {
    >section {
      >section:first-child {
        section {
          >div:first-child {
            h2 {} }
          >div:last-child {
            h2 {
              img {}
              p {} }
            dl {
              dt {}
              dd {
                ul {
                  li {
                    p {} } } } }
            .whiteout-button {
              position: relative;
              right: 0;
              bottom: 0;
              text-align: right;
              button {} } } }

        div.languages,div.terms,div.privacy-policy {
          h3 {
            p {}
            img {} }
          >p {}
          >img {} }
        div.logout {
          button {} } } }
    footer {} } }

