.history-detail {
  .amount-and-fee{
    display: flex;
    justify-content: space-between;
    .icon{
      width: calc( 100% * 32 / 360 );
      @media screen and (max-width: 480px) {
        width: calc( 100% * 45 / 360 );
      }
    }
    .expand{
      width:12px;
    }
    .amount{
      width: calc( 100% * 140 / 360 );
      @media screen and (max-width: 480px) {
        width: calc( 100% * 120 / 360 );
      }
      display:flex;
      justify-content: left;
      p:first-child{
        margin-bottom: 8px;
        margin-right: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
      }
      p:last-child{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
      }
    }

    .fee{
      padding-right: 6px;
      width: calc( 100% * (360 - 32 - 140 - 12) / 375 );
      @media screen and (max-width: 480px) {
        width: calc( 100% * (360 - 50 - 120 - 12) / 360 );
      }
      display:flex;
      justify-content: right;
      p:first-child{
        margin-right: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
      }
      p:last-child{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
      }
    }
  }
  .account{
    h3{
      display: flex;
      justify-content: space-between;
      .icon{
        width: calc( 100% * 32 / 360 );
        @media screen and (max-width: 480px) {
          width: calc( 100% * 45 / 360 );
        }
      }
      .expand{
        width:12px;
      }
      .contents{
        border: 1px solid black;
        border-radius: 2px;
        width: calc( 100% * 140 / 360 + 100% * (360 - 32 - 140 - 12) / 375);
        @media screen and (max-width: 480px) {
          width: calc( 100% * 120 / 360 + 100% * (360 - 50 - 120 - 12) / 360 );
        }
        padding: 8px 8px;
        .icon-and-name{
          display: flex;
          img{
            margin-top: -4px;
            margin-right: 8px;
            width:16px;
          }
          p{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            color: #2F374E;
          }
        }
        ul{
          margin-left: 24px;
          list-style: none;
          li>ul{
            display: flex;
            justify-content: space-between;
            margin: 0;
            >li{
              text-align: left;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
            }
            >li:first-child{
              width:40%;
            }
            >li:last-child{
              width:60%;
            }
          }
        }
      }
    }
    margin-bottom: 8px
  }
}
