
.cria header#initial-setting {
  h1 img {
    width: 67px; 
  } 
}

.prekyu header#initial-setting {
  h1 img {
    width: 180px; 
  } 
}

header#initial-setting {
  padding-bottom: 30px;
  padding-top: 40px;

  @media screen and (max-width: 760px) {
    padding-bottom: 20px;
    padding-top: 68px; 
  }

  h1 img {
    height: auto;
    margin: 0 auto;
  } 
}

#initial-setting > #menu-buttons {
  display: flex;
  position: absolute;
  right: 20vw;
  top: 5vh; }


@media screen and (max-width: 1060px) {
  #initial-setting > #menu-buttons {
    right: 10vw; } }

@media screen and (max-width: 760px) {
  #initial-setting > #menu-buttons {
    justify-content: center;
    right: 0;
    top: 20px;
    width: 100vw; } }

@media screen and (max-width: 460px) {
  #initial-setting > #menu-buttons {
    justify-content: center;
    left: calc((100vw - 220px) / 2);
    right: auto;
    top: 20px;
    width: 220px; } }


