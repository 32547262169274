.cria header.only-logo {
  background: linear-gradient(#00B9B2 0%, #369ABF 100%);
  div{
    img{
      width: 30px;
    }
  }
}

.prekyu header.only-logo {
  background: linear-gradient(#EAA303 0%, #F15A22 100%);
  div{
    img{
      width: 80px;
    }
  }
}

header.only-logo {
  height: 60px;
  div {
    max-width: 960px;
    width: 90vw;
    margin: 0 auto;
    padding: 0;
    img {
      width: 80px;
      height: 30px;
      margin-top: calc( (60px - 30px) / 2 ); 
    } 
  } 
}
