$standardWidth: 300px;
$standardInnerWidth: 220px;

main.reminder {
  padding: 0px;

  .contents-inner {
    color: #fff;
    max-width: 620px;
    padding: 0;
    text-align: center;
    h2 {
      margin-bottom: 24px; 
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }

    > p {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      text-align: center; 
    }

    form {
      margin: 0 auto;
      text-align: center;
      width: $standardWidth; 
    }

    .email-or-sms {
      margin-top: 24px; 
    }

    .customer-number {
      margin-top: 24px; 
    }

    .submit{
      .error {
        color: #cc0000;
        font-size: 13px;
        text-align: center; 
        margin-top: 13px;
        margin-bottom: 8px;
      }
      div{
        margin-right:0;
        margin-left:0;
        margin-bottom:32px;
      }

      div:first-child{
        margin-top: 40px ; 
      }
    }

    .left-allow-link {
      margin-top: 24px; 
    } 
  } 
}

