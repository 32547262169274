.prekyu main#passcode {
  > div {
    h2 {
      color: #F15A22;
    }
  }
}

main#passcode {
  padding-top: 0;
  > div {
    background: #fff;
    border-radius: 8px;
    height: 359px;
    margin: 0 auto;
    margin-top: 10vh;
    padding-top: 30px;
    max-width: 460px;
    width: 90vw;

    h2 {
      color: #00B9B2;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      margin: 0 auto 30px;
      text-align: center; }

    p {
      color: #2f374e;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      text-align: center; }


    form {
      margin-top: 30px;

      div.buttons {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 60%;
      }

      div.submit-button {
        margin-top: 30px;
        text-align: center; 
      } 
    } 
  } 
}

