div.privacy-policy-and-terms.no-details {
  > main {
    > section {
      > section {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
}

.prekyu div.privacy-policy-and-terms {
  footer {
    background: linear-gradient(#eaa303 0%, #f15a22 100%);
  }
}

div.privacy-policy-and-terms {
  background: #ffffff;
  > main {
    padding: 0;
    margin: 0 auto;
    width: fit-content;

    > section {
      display: flex;
      > section {
        max-width: 960px;
        width: 100%;
        text-align: left;

        padding-top: 50px;

        div {
          display: flex;
          margin-bottom: 60px;
          h2 {
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            text-align: left;
            color: #2f374e;
            margin-right: 6px;
          }
        }
      }

      div.translater-company {
        margin-top: 8px;
      }

      h3 {
        margin-bottom: 14px;
      }
      ul.number {
        > li {
          > div {
            > p:first-child {
              margin-right: 8px;
            }
          }
        }
      }
      ul.bracket {
        margin-left: 14px;
      }
      ul.number,
      ul.bracket {
        > li {
          > div {
            display: flex;
            margin: 0;
            > p {
              margin-top: 0;
            }
          }
        }
      }
      ul.update-history {
        margin-top: 14px;
      }
      ul {
        list-style: none;
        > li {
          > ul > li {
            margin: 0;
            padding: 0;
          }
          font-weight: 100;
          font-size: 12px;
          line-height: 22px;
          color: #2f374e;
          margin-bottom: 6px;
        }
        > li:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  footer {
    margin-top: 50px;
    font-weight: 500;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    color: #fff;

    padding-top: 50px;

    width: 100%;
    height: 150px;
    background: linear-gradient(#00b9b2 0%, #369abf 100%);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
    position: relative;
  }
}
